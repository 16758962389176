import { observable } from 'mobx';
import { formatDate } from 'client/tools';

export class DocumentFormStore {
	@observable isLoading = true;
	@observable document = null;

	constructor(document, dialog) {
		this.document = document;
		this.dialog = dialog;
		this.init();
	}

	init = async () => {
		this.isLoading = false;
	};

	beforeSave = () => {
		const contactUser = this.dialog.name || this.dialog.username || this.dialog.phone || this.dialog.email;
		this.document.name = `${contactUser} ${formatDate(new Date(), 'dd.MM.yyyy HH:mm')}`;
	};
}
