import appStore from 'client/store';
export const getDefaultColumnDefs = () => ({
	// дефолтные пропсы для каждого столбца. Настройки столбца имеют приоритет над дефолтным
	enableValue: true, // агрегация
	enableRowGroup: true, // группировка для столбца
	enablePivot: true, // пивот(значения в столбцах)
	sortable: true,
	filter: true,
	resizable: true,
	initialWidth: '250px',
	floatingFilter: appStore.local.agGrid?.floatingFilter,
});

