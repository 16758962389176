import React from 'react';
import classNames from 'classnames';
import { IconButton } from './IconButton';
import { renderFaIcons } from 'client/tools/renderFaIcons';
import t from 'i18n';

const disableRipple = true;

const makeIconButton =
	({ className, ...rest }) =>
	(props) =>
		<IconButton {...rest} {...props} className={classNames(props.className, className)} />;

export const ExchangeButton = makeIconButton({ icon: renderFaIcons.exchange, className: 'exchange-icon-button' });

export const ChartButton = makeIconButton({ icon: renderFaIcons.chartIcon });
export const ChartColumnButton = makeIconButton({ icon: renderFaIcons.chartIconColumn });
export const EditIconButton = makeIconButton({ icon: renderFaIcons.editIcon, className: 'edit-btn' });
export const SaveIconButton = makeIconButton({ icon: renderFaIcons.save, className: 'save-button-icon' });
export const FileImportIconButton = makeIconButton({ icon: renderFaIcons.fileImport, className: 'fileImport-button-icon' });
export const FileExportIconButton = makeIconButton({ icon: renderFaIcons.fileExport, className: 'fileExport-button-icon' });

// для кнопок с текстом, нужно создавать обертки, иначе перевод следит
export const AttachButton = (props) => {
	const Button = makeIconButton({
		icon: renderFaIcons.attachIcon,
		className: 'attach-button',
	});
	return <Button {...props} endText={t('files')} />;
};

export const AddUserButton = (props) => {
	const Button = makeIconButton({
		endText: t('members'),
		icon: renderFaIcons.memberIcon,
		className: 'add-user-button',
	});
	return <Button {...props} endText={t('members')} />;
};

export const CancelIconButton = (props) => {
	const Button = makeIconButton({
		icon: renderFaIcons.xMark,
		className: 'cancel-button-icon',
		disableRipple,
	});
	return <Button endText={t('cancel')} {...props} />;
};

export const ApproveIconButton = (props) => {
	const Button = makeIconButton({ icon: renderFaIcons.checkIcon, className: 'check-button-icon', title: t('save') });
	return <Button {...props} endText={t('save')} />;
};

export { IconButton } from './IconButton';
export { DeleteIconButton } from './DeleteIconButton';
