// сюда надо вынести все пути, формата MODULE_PAGE_PATH

export const MANAGEMENT_PATH = '/management';
export const MANAGEMENT_MANAGEMENT_TASKS_PATH = `${MANAGEMENT_PATH}/tasks`;
export const MANAGEMENT_PROJECTS_PATH = `${MANAGEMENT_PATH}/projects`;
export const MANAGEMENT_DASHBOARD_PATH = `${MANAGEMENT_PATH}/dashboard`;
export const MANAGEMENT_SETTINGS_PATH = `${MANAGEMENT_PATH}/settings`;

export const STOREHOUSE_PATH = '/storehouse';
export const STOREHOUSE_NOMENCLATURES_PATH = `${STOREHOUSE_PATH}/nomenclatures`;
export const STOREHOUSE_WAREHOUSES_PATH = `${STOREHOUSE_PATH}/warehouses`;
export const STOREHOUSE_NOMENCLATUREMOVEMENTS_PATH = `${STOREHOUSE_PATH}/nomenclatureMovements`;
export const STOREHOUSE_NOMENCLATUREGROUPS_PATH = `${STOREHOUSE_PATH}/nomenclaturegroups`;
export const STOREHOUSE_SETTINGS_PATH = `${STOREHOUSE_PATH}/settings`;
export const STOREHOUSE_REPORTS_PATH = `${STOREHOUSE_PATH}/reports`;

const MANUFACTURE_PATH = '/manufacture';
export const MANUFACTURE_EQUIPMENTS_PATH = `${MANUFACTURE_PATH}/equipments`;
export const MANUFACTURE_MATERIALS_PATH = `${MANUFACTURE_PATH}/materials`;
export const MANUFACTURE_PRODUCTION_PROCESSES_PATH = `${MANUFACTURE_PATH}/processes`;
export const MANUFACTURE_TECH_MAP_PATH = `${MANUFACTURE_PATH}/tech-maps`;
export const MANUFACTURE_PRODUCTION_WORKSHOPS_PATH = `${MANUFACTURE_PATH}/workshops`;
export const MANUFACTURE_SETTINGS_PATH = `${MANUFACTURE_PATH}/settings`;
export const MANUFACTURE_PRODUCTION_ORDERS_PATH = `${MANUFACTURE_PATH}/production-orders`;
export const MANUFACTURE_REPORTS_PATH = `${MANUFACTURE_PATH}/reports`;
export const MANUFACTURE_DASHBOARD_PATH = `${MANUFACTURE_PATH}/dashboard`;
export const MANUFACTURE_STATUSES_PATH = `${MANUFACTURE_SETTINGS_PATH}/productionOrderStatus`;
