import store from '@appStore';
import { Theme } from '@consta/uikit/Theme';
import { observer } from 'mobx-react';
import React from 'react';

export const withTheme = (Component) => {
	@observer
	class _Theme extends React.Component {
		render() {
			return (
				<Theme preset={store.selectedThemePreset}>
					<Component {...this.props} />
				</Theme>
			);
		}
	}

	return _Theme;
};
