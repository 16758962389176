import React from 'react';
import t from 'i18n';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import './style.scss';

@observer
export class ListScrollLoad extends React.Component {
	@observable loadingMore = false;
	wrapperEl = null;
	constructor(props) {
		super(props);
	}

	onMount = (el) => {
		if (el) {
			this.wrapperEl = el;
			this.wrapperEl.addEventListener('scroll', this.onScroll);
		}
	};

	onScroll = async (e) => {
		const { records } = this.props;
		if (this.loadingMore || records.length >= records.totalRecords) return;
		const bottomScroll = e.target.scrollHeight - e.target.scrollTop - e.target.offsetHeight;
		if (bottomScroll < 50) {
			this.loadingMore = true;
			if (this.props.onLoadMore) await this.props.onLoadMore();
			this.loadingMore = false;
		}
	};

	componentWillUnmount() {
		if (this.wrapperEl) this.wrapperEl.removeEventListener('scroll', this.onScroll);
	}

	render() {
		const { renderItem, records } = this.props;
		return (
			<div className='wrapper-history-widget' ref={this.onMount}>
				{records.length ? records.map((record, index) => renderItem(record, index)) : t('history.notFound')}
			</div>
		);
	}
}
