import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import t from 'i18n';
import store from 'client/store';
import classNames from 'classnames';
import { Button, ConfirmPopup, Editor, IconButton, triggerErrorNotification, TruncateText } from 'components';
import './postMessage.scss';
import SendIcon from '../../img/icons/send-message.svg';
import { Input } from '@smartplatform/ui';
import { faPaperclip, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

@observer
export class PostMessage extends React.Component {
	@observable value = '';
	@observable isSubmitting = false;
	@observable error = null;
	@observable showChangedPopup = false;
	@observable isEditMode = false;
	ref = React.createRef();
	@observable files = [];
	@observable message = null;

	static propTypes = {
		onSubmit: PropTypes.func,
		relation: PropTypes.string,
		record: PropTypes.object,
		className: PropTypes.string,
		placeholder: PropTypes.string,
	};

	static defaultProps = {
		relation: 'messages',
		placeholder: t('postMessage.placeholder'),
	};

	constructor(props) {
		super(props);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside = (event) => {
		// клик вне коммента
		if (this.isEditMode && this.ref && !this.ref.current?.contains(event.target)) {
			if (this.value || !!this.files.length) {
				this.showChangedPopup = true;
			} else {
				this.onCancel();
			}
		}
	};

	toggleIsEditMode = (value) => {
		if (value) {
			document.addEventListener('mousedown', this.handleClickOutside);
		} else {
			document.removeEventListener('mousedown', this.handleClickOutside);
		}
		this.isEditMode = value;
	};

	onEditorInit = (editor) => {
		this.editor = editor;
		if (this.props.onToggleEdit) this.props.onToggleEdit();
	};

	onChange = (value) => {
		this.value = value;
	};

	onSave = async () => {
		this.error = null;
		if (this.value.trim().length === 0 && !this.files.length) {
			triggerErrorNotification(t('postMessage.emptyMessage'));
			return;
		}
		try {
			this.isSubmitting = true;

			this.message = new store.model.Comment();
			this.message.text = this.editor.getContent();
			await this.message.save();

			await this.props.record[this.props.relation].add(this.message.id);
			const promises = this.files.map(this.upload);
			await Promise.all(promises);
			this.props.onSubmit && this.props.onSubmit(this.message);
			this.value = '';
			this.files = [];
			this.isSubmitting = false;
			this.toggleIsEditMode(false);
		} catch (e) {
			this.error = e.message;
		}
	};

	onOpenConfirmPopup = () => (this.showChangedPopup = true);
	onCloseConfirmPopup = () => (this.showChangedPopup = false);

	onCancel = () => {
		if (!this.showChangedPopup && this.value) {
			this.showChangedPopup = true;
			return;
		}

		this.value = '';
		this.files = [];
		this.toggleIsEditMode(false);
		this.showChangedPopup = false;
	};

	onAttach = async (e) => {
		const newFiles = [];
		for (let file of e.target.files) {
			const attachment = new store.model.Attachment();
			attachment.filename = file.name;
			newFiles.push({
				attachment,
				file,
				uploadProgress: 0,
				done: false,
				error: null,
			});
		}
		this.files = [...this.files, ...newFiles];
	};

	upload = async (uploadObj) => {
		await uploadObj.attachment.save();
		await uploadObj.attachment.owner;
		try {
			const res = await this.uploadFile(uploadObj);
			await this.message.attachments.add(uploadObj.attachment.id);
			uploadObj.done = true;
		} catch (error) {
			if (error.status === 413) {
				triggerErrorNotification(t('file.fileTooBig'));
			} else {
				triggerErrorNotification(t('file.uploadError'));
			}
			await uploadObj.attachment.delete();
		}
	};

	uploadFile = (uploadObj) =>
		new Promise((resolve, reject) => {
			uploadObj.attachment
				.uploadFile('filename', uploadObj.file)
				.on('progress', (event) => this.handleFileProgress(uploadObj, event))
				.end((error, result) => {
					if (!error) {
						resolve(result);
					} else {
						reject(error);
					}
				});
		});

	handleFileProgress = (uploadObj, event) => {
		uploadObj.uploadProgress = event.percent;
	};

	deleteAttachment = (index) => {
		this.files.splice(index, 1);
	};

	render() {
		let { className, placeholder } = this.props;
		const { onSave, onCloseConfirmPopup, toggleIsEditMode, onCancel } = this;
		className = classNames('post-message-editor', className);

		return (
			<div className='wrapper-post-message'>
				{this.isEditMode ? (
					<div className={className} ref={this.ref}>
						<Editor
							autoFocus
							value={this.value}
							onChange={this.onChange}
							autoResize
							minHeight={180}
							onInit={this.onEditorInit}
							menubar={false}
							toolbar='bold italic underline alignleft aligncenter alignjustify alignright numlist bullist image media'
							disabled={this.isSubmitting}
							maxHeight={180}
							mediaModel={store.model.Attachment}
						/>
						<div className='actions'>
							<div className='attached-files'>
								{this.files.map((file, index) => (
									<div className='item-attached-file' key={index}>
										<TruncateText>{file.attachment.filename}</TruncateText>
										<IconButton
											icon={<FontAwesomeIcon icon={faTrashCan} />}
											className='delete-btn'
											noBorder={true}
											size='small'
											onClick={() => this.deleteAttachment(index)}
										/>
									</div>
								))}
							</div>
							<div className='upload'>
								<input type='file' id='attach-files-message' onChange={this.onAttach} multiple />
								<label htmlFor='attach-files-message'>
									<Button
										onClick={() => document.getElementById('attach-files-message').click()}
										startIcon={<FontAwesomeIcon icon={faPaperclip} />}
										variant='default'
									>
										{t('toAttachFile')}
									</Button>
								</label>
							</div>
							<Button onClick={onSave} disabled={!this.value && !this.files.length} endIcon={<SendIcon />}>
								{t('postMessage.send')}
							</Button>
						</div>
					</div>
				) : (
					<Input className='input' placeholder={placeholder} onClick={() => toggleIsEditMode(true)} />
				)}
				{this.error && <div className='error'>{this.error}</div>}
				{this.showChangedPopup && (
					<ConfirmPopup
						onClose={onCloseConfirmPopup}
						onConfirm={onCancel}
						title={t('comment.confirmTitle')}
						description={t('comment.confirmDescription')}
						buttonText={t('confirm')}
					/>
				)}
			</div>
		);
	}
}
