import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Tabs, Tab } from '@smartplatform/ui';
import { Dictionary, IconTitle } from 'components';
import t from 'i18n';
import { MESSAGES_SETTINGS_PATH } from 'client/modules/messages/constants';
import { DialogSources } from 'client/modules/messages/settings/dialog-sources';
import store from 'client/store';

export class SettingTabs extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Tabs>
				<Route path={MESSAGES_SETTINGS_PATH} exact>
					<Redirect to={`${MESSAGES_SETTINGS_PATH}/statuses`} />
				</Route>
				<Tab
					path={`${MESSAGES_SETTINGS_PATH}/statuses`}
					title={<IconTitle text={t('dialogStatus.plural')} />}
					render={(props) => <Dictionary {...props} modelName='DialogStatus' />}
					disabled={!store.model.DialogStatus || !store.model.DialogStatus.INFO.WRITE}
				/>
				<Tab
					path={`${MESSAGES_SETTINGS_PATH}/dialog-sources`}
					title={<IconTitle text={t('dialogSource.plural')} />}
					render={(props) => <DialogSources {...props} />}
					disabled={!store.model.DialogSource || !store.model.DialogSource.INFO.WRITE}
				/>
			</Tabs>
		);
	}
}
