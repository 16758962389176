import { MODEL_RENDERS } from './modelRenders';
import { fioShort } from 'client/tools';
import { User } from 'components';

/**
 * Функция отдаёт дефолтные значения для рендера и группировки дополнительных полей модели
 */

export const getAgGridCols = (customFields = [], options = {}) =>
	customFields.map((field) => {
		const { type, name, code } = field;
		const col = {
			headerName: name,
			field: code,
		};
		if (field.isRelation) {
			field.cellDataType = 'object';
			if (field.type === 'User') {
				col.valueFormatter = ({ value }) => (value ? fioShort(value) : null);
				col.cellRenderer = ({ value }) =>
					value ? <User user={value} showBlockedIcon={false} popoverFullName={false} showOnlineStatus={false} /> : null;
			} else {
				col.valueFormatter = ({ value }) => (value ? MODEL_RENDERS[type](value) : null);
			}
		} else {
			if (type === 'Date') {
				col.cellDataType = 'date';
			} else if (type === 'Datetime') {
				col.cellDataType = 'dateTime';
			} else if (type === 'Boolean') {
				col.cellDataType = 'boolean';
			}
		}
		return col;
	});

