import React from 'react';
import { observer } from 'mobx-react';
import { ManySelect } from 'components/selects/ManySelect';

export const ToolbarManySelect = observer(({ label, ...recordSelectProps }) => {
	return (
		<div className='toolbar-list-select'>
			{label && <label>{label}</label>}
			<ManySelect {...recordSelectProps} />
		</div>
	);
});
