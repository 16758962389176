import React from 'react';
import classNames from 'classnames';
import './style.scss';

export const SortIcon = ({ trigger, ...restProps }) => (
	<div className={classNames('icon-sort', { sorted: trigger })} {...restProps}>
		<div className='top' />
		<div className='medium' />
		<div className='bot' />
	</div>
);
