import store from 'client/store';

const DEFAULT_MODULES = ['contragents', 'admin'];

export function getAvailableModules() {
	const MODULES = require('client/modules').default;
	const availableModules = [];
	const configModules = [...(store.model.config.modules || []), ...DEFAULT_MODULES].map((module) =>
		module instanceof Object ? module.name : module
	);

	for (let module of Object.values(MODULES)) {
		if (!configModules.includes(module.code)) continue;
		if (module.enabled === false) continue;
		if (typeof module.enabled === 'function' && !module.enabled(store)) continue;
		availableModules.push(module);
	}

	return availableModules;
}

