import t from 'i18n';

export const PRODUCT_INCLUDE = [
	{
		relation: 'featureValues',
	},
	{
		relation: 'nomenclatures',
		scope: {
			fields: ['id', 'image'],
			include: [
				{
					relation: 'featuresValues',
					scope: {
						fields: ['id', 'featureId', 'stringValue', 'numberValue', 'booleanValue', 'featureValueId'],
						include: [
							{
								relation: 'featureValue',
								scope: {
									where: { id: { neq: null } },
									include: ['feature'],
								},
							},
							{
								relation: 'feature',
								scope: {
									where: {
										id: { neq: null },
									},
								},
							},
						],
					},
				},
				{
					relation: 'prices',
					scope: {
						where: {
							rangeId: { neq: null },
						},
						fields: ['id', 'price', 'rangeId'],
						include: [
							{
								relation: 'amountRange',
								scope: {
									fields: ['id', 'amountFrom', 'amountTo'],
								},
							},
						],
						order: 'rangeId asc',
					},
				},
			],
			order: 'id asc',
		},
	},
	{
		relation: 'productNomenclaturesFeatures',
		scope: {
			where: {
				featureId: { neq: null },
			},
			include: [
				{
					relation: 'feature',
					scope: {
						include: [{ relation: 'featureValues' }],
					},
				},
			],
			order: 'weight desc',
		},
	},
	{ relation: 'category' },
	{ relation: 'product' },
];

export const FEATURE_TYPE_KEYS = {
	string: 'stringValue',
	number: 'numberValue',
	boolean: 'booleanValue',
	list: 'featureValueId',
};

export const COUNTS_PER_PAGE = [
	{ label: 20, value: 20 },
	{ label: 40, value: 40 },
	{ label: 60, value: 60 },
];
export const COLUMNS_EXPORT = {
	name: t('name'),
	article: t('article'),
	categoryName: t('category.title'),
	featureNames: t('feature.plural'),
	featureValues: t('feature.valuesPlural'),
	priceRanges: t('product.amountRanges'),
	prices: t('price'),
};
