import t from 'i18n';
export const INCLUDES_SINGLE_DIALOG = [
	{
		relation: 'tasks',
		scope: {
			include: [{ relation: 'project', scope: { include: [{ relation: 'members' }] } }],
		},
	},
	'documents',
	'orders',
	'source',
	'employee',
];
export const CATEGORIES_ERP_ITEMS = [
	{ label: t('task.title'), value: 'Task' },
	{ label: t('order.title'), value: 'Order' },
	{ label: t('document.title'), value: 'Document' },
];
export const INCLUDE_ORDER = [{ relation: 'list', scope: { fields: ['name', 'id', 'color'] } }, 'manager'];
export const INCLUDE_DOCUMENT = [{ relation: 'type', scope: { fields: ['name', 'id'] } }];

export const NEW_CONTRAGENT = 'NEW_CONTRAGENT';

export const DIALOG_SOURCES = {
	EMAIL: 'EMAIL',
	TLG: 'TLG',
	VK: 'VK',
	WA: 'WA',
};

export const DIALOG_TABS = {
	MESSAGES: 'MESSAGES',
	LOG: 'LOG',
	CONTACT: 'CONTACT',
};
