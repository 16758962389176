import React from 'react';
import { observer } from 'mobx-react';
import { renderNameOrDescription, renderPlaceholder } from 'client/tools';
import store from 'client/store';
import t from 'i18n';
import { toJS } from 'mobx';
import { RecordSelect } from './RecordSelect';

@observer
export class ManySelect extends React.Component {
	static defaultProps = {
		searchPlaceholder: t('search'),
		showClearButton: true,
		useDescriptionField: false,
	};

	get showValue() {
		const { value, computed = renderNameOrDescription, placeholder } = this.props;
		if (Array.isArray(toJS(value))) {
			if (value.length === 1) {
				return computed(value[0]);
			}
			if (value.length > 1) {
				return `${t('selected')}: ${value.length}`;
			}
			return renderPlaceholder(placeholder);
		}
		return computed(value);
	}
	render() {
		const { computed, model, showValue, maxItems, useDescriptionField, itemsPerPage, ...restProps } = this.props;

		//useDescriptionField используем если в записи используется description вместо name, как в случае с моделью Order, чтобы сортировка в селекте была по алфавиту.
		const filter = {
			fields: useDescriptionField ? ['id', 'description'] : ['id', 'name'],
			order: useDescriptionField ? 'description asc' : 'name asc',
		};
		return (
			<RecordSelect
				itemsPerPage={itemsPerPage || store.maxSelectItems}
				maxItems={maxItems || store.maxSelectItems}
				model={model}
				computed={computed || renderNameOrDescription}
				showValue={showValue || this.showValue}
				filter={filter}
				{...restProps}
			/>
		);
	}
}
