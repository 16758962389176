import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Avatar } from '@smartplatform/ui';
import { fioShort, relative } from 'client/tools';
import classNames from 'classnames';
import { OnlineBadge } from 'components';

import store from 'client/store';
import './log.scss';

@observer
export class Log extends Component {
	render() {
		const { className, instanceLog, actions, roles = [], content, controls } = this.props;

		return (
			<div className={classNames('log', className)}>
				<OnlineBadge isOnline={store.onlineUsersMap.has(instanceLog.owner?.id)}>
					<Avatar className='avatar' user={instanceLog.owner} size={32} />
				</OnlineBadge>
				<div className='what'>
					<div className='top'>
						<div className='subject-date'>
							<span className='subject'>{fioShort(instanceLog.owner)}</span>
							<span className='date'>{relative(instanceLog.createdAt)}</span>
						</div>
						<div className='actions'>
							{actions.length > 0 &&
								actions.map((action, i) => {
									return (
										<span className='action' key={i}>
											<>{action.title} </>
											<span className='subject'>{action.subject}</span>
										</span>
									);
								})}
						</div>

						{roles.length > 0 && (
							<div className='roles'>
								{roles.map((role, i) => (
									<div key={i} className='role'>
										{role}
									</div>
								))}
							</div>
						)}
						{controls}
					</div>
					{content}
				</div>
			</div>
		);
	}
}

