import React, { Component } from 'react';
import { ButtonGroup } from '@smartplatform/ui';
import { Button } from 'components';
import classNames from 'classnames';
import t from 'i18n';

export class ToolbarButtonGroup extends Component {
	render() {
		const { buttons, closed, buttonOnClick, className, label } = this.props;
		const _className = classNames('closed-filters', className);

		return (
			<div className={_className}>
				{label && <label>{label}</label>}
				<ButtonGroup>
					{buttons.map(({ name, value, label }, index) => (
						<Button
							variant={closed === value ? 'primary' : 'default'}
							key={index}
							onClick={() => buttonOnClick(value)}
							text={label || t('task.' + name)}
						/>
					))}
				</ButtonGroup>
			</div>
		);
	}
}
