import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { NumberInput } from '@smartplatform/ui';
import { DeleteIconButton } from 'components';
import './amountRange.scss';
import t from 'i18n';

@inject('productStore')
@observer
export class AmountRange extends React.Component {
	onChange = (prop) => (value) => {
		this.props.range[prop] = value;
		this.props.amountRangeStore.checkValidation();
		this.props.amountRangeStore.checkToSave();
	};

	onDelete = async () => {
		const { amountRanges, rangesToDelete, rangesToSave, checkValidation } = this.props.amountRangeStore;
		const { range, index } = this.props;
		if (range.id) rangesToDelete.add(range);
		rangesToSave.delete(range);
		amountRanges.splice(index, 1);
		checkValidation();
	};

	getNumberInputProps = (prop) => ({
		size: 10,
		value: this.props.range[prop],
		onChange: this.onChange(prop),
		integerOnly: true,
		positiveOnly: true,
	});

	render() {
		const { getNumberInputProps, props } = this;
		const { notValidValues = {} } = props;

		return (
			<div className='wrapper-amount-range'>
				<div className='amount-range'>
					<div className='inputs'>
						<div className={`input-block ${notValidValues.amountFrom && 'invalid'}`}>
							<span>{t('pricePosition.amountFrom')}</span>
							<NumberInput autoFocus {...getNumberInputProps('amountFrom')} />
						</div>
						<div className={`input-block ${notValidValues.amountTo && 'invalid'}`}>
							<span>{t('pricePosition.amountTo')}</span>
							<NumberInput {...getNumberInputProps('amountTo')} />
						</div>
					</div>
					<div className='actions'>
						<DeleteIconButton onConfirm={this.onDelete} />
					</div>
				</div>
			</div>
		);
	}
}

