import React from 'react';
import PropTypes from 'prop-types';

import { Avatar, Popover } from '@smartplatform/ui';
import { fioOrEmpty, fioShortOrEmpty, renderFaIcons } from 'client/tools';
import { TruncateText } from '../text';
import classNames from 'classnames';
import { OnlineBadge } from 'components';

import './style.scss';
import store from 'client/store';
import { observer } from 'mobx-react';

@observer
export default class User extends React.Component {
	render() {
		const {
			user,
			size = 22,
			full,
			text,
			popoverFullName = true,
			popoverProps,
			showBlockedIcon = true,
			showOnlineStatus = true,
		} = this.props;

		const renderUserText = () => {
			let _content = text;

			if (!_content) {
				_content = full ? fioOrEmpty(user) : fioShortOrEmpty(user);

				if (!_content) {
					_content = user.email || `userId: ${user.id}`;
				}
			}

			return _content;
		};

		let userText = renderUserText();
		let _user = <Avatar user={user} size={size} />;
		if (showOnlineStatus) {
			_user = <OnlineBadge isOnline={store.onlineUsersMap.has(user.id)}>{_user}</OnlineBadge>;
		}

		const contentRender = (
			<div className='avatar-fio'>
				{_user}

				<TruncateText className='fio'>{userText}</TruncateText>
				{showBlockedIcon && user.isBlocked ? renderFaIcons.userLock : ''}
			</div>
		);

		const contentPopover = fioOrEmpty(user);

		if (!popoverFullName || !contentPopover) {
			return contentRender;
		} else {
			const props = {
				...popoverProps,
				className: classNames('wrapper-popover-user', popoverProps?.className),
			};
			return (
				<Popover content={contentPopover} {...props}>
					{contentRender}
				</Popover>
			);
		}
	}
}

User.propTypes = {
	user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	size: PropTypes.number,
	full: PropTypes.bool,
	popoverFullName: PropTypes.bool,
	popoverProps: PropTypes.object,
	showBlockedIcon: PropTypes.bool,
};

