import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button, Popup, Select } from '@smartplatform/ui';
import { renderPlaceholder, renderUser } from 'client/tools';
import store from 'client/store';
import t from 'i18n';
import './userSelectOld.scss';

@observer
export class UserSelectOld extends Component {
	static propTypes = {
		showValue: PropTypes.any,
		onChange: PropTypes.func,
	};

	@observable items = [];
	@observable searchItems = [];
	@observable showConfirmPopUp = false;
	@observable stashedValue = null;

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidUpdate(prevProps, prevState) {
		if (JSON.stringify(prevProps.filter) !== JSON.stringify(this.props.filter)) {
			this.init();
		}
	}

	init = async () => {
		this.items = [];

		const users = await store.model.User.find(this.props.filter || {});
		const index = users.findIndex((user) => user.id === store.model.user.id);
		let me = {};
		if (~index) {
			users.splice(index, 1);
			me = { label: this.renderUser(store.model.user), value: store.model.user };
			this.items.unshift(me);
		}
		this.items = [...this.items, ...users.map((user) => ({ label: this.renderUser(user), value: user }))];
		this.searchItems = [...this.items];
	};

	onChange = (value) => {
		if (this.props.onChange) {
			this.props.onChange(value);
		} else {
			this.props.value = value;
		}
		this.closeConfirm();
	};

	showConfirm = (value) => {
		if (value?.isBlocked) {
			this.showConfirmPopUp = true;
			this.stashedValue = value;
			return false;
		}
		this.onChange(value);
	};

	closeConfirm = () => {
		this.showConfirmPopUp = false;
	};

	renderUser = (value) => {
		let text = store.model.user.id === value.id ? t('task.me') : '';
		return renderUser(value, ...[,], text);
	};

	isValueCorrectObject = (value) => typeof value === 'object' && value.hasOwnProperty('label') && value.hasOwnProperty('value');

	getShowValue = (value) => {
		let showValue = renderPlaceholder(t('select'));
		if (value) {
			showValue = this.renderUser(value);
		}

		return showValue;
	};

	onSearch = (str) => {
		this.searchItems = [...this.items];
		const searchStr = str.toLowerCase();

		if (str) {
			this.searchItems = this.items.filter(({ value }) => {
				return (
					value.lastName?.toLowerCase()?.includes(searchStr) ||
					value.firstName?.toLowerCase()?.includes(searchStr) ||
					value.secondName?.toLowerCase()?.includes(searchStr)
				);
			});
		}
	};

	render() {
		const { showValue, value } = this.props;

		let _value = value;
		if (_value && !this.isValueCorrectObject(value)) {
			_value = { label: this.renderUser(value), value };
		}

		let _showValue = showValue || this.getShowValue(value);

		const confirmContent = (
			<>
				{t('user.confirmSelectBlockedUser')}
				<div className='wrapper-control-confirm'>
					<Button onClick={() => this.onChange(this.stashedValue)}>{t('yes')}</Button>
					<Button onClick={this.closeConfirm}>{t('no')}</Button>
				</div>
			</>
		);

		return (
			<>
				<Select
					{...this.props}
					items={this.searchItems}
					onChange={this.showConfirm}
					empty={t('nobody')}
					showValue={_showValue}
					onSearch={this.onSearch}
					value={_value}
				/>
				{this.showConfirmPopUp && (
					<Popup className='popup-confirm-block-user' onClose={this.closeConfirm}>
						{confirmContent}
					</Popup>
				)}
			</>
		);
	}
}

