import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faPaperclip,
	faPen,
	faUserPlus,
	faXmark,
	faChartLine,
	faEye,
	faEyeSlash,
	faCheck,
	faClone,
	faInfoCircle,
	faTag,
	faPlus,
	faPlusCircle,
	faChevronDown,
	faChevronUp,
	faCommentAlt,
	faUserLock,
	faLock,
	faExchange,
	faChartColumn,
	faFilter,
	faMinus,
	faArrowRight,
	faFileImport,
	faFileExport,
	faSave,
} from '@fortawesome/free-solid-svg-icons';

const renderFaIcons = {
	attachIcon: faPaperclip,
	editIcon: faPen,
	memberIcon: faUserPlus,
	xMark: faXmark,
	chartIcon: faChartLine,
	chartIconColumn: faChartColumn,
	eyeIcon: faEye,
	eyeSlashIcon: faEyeSlash,
	checkIcon: faCheck,
	copyIcon: faClone,
	info: faInfoCircle,
	priceListIcon: faTag,
	plus: faPlus,
	circlePlus: faPlusCircle,
	arrowDown: faChevronDown,
	arrowUp: faChevronUp,
	comment: faCommentAlt,
	userLock: faUserLock,
	lock: faLock,
	filter: faFilter,
	exchange: faExchange,
	minus: faMinus,
	arrowRight: faArrowRight,
	save: faSave,
	fileImport: faFileImport,
	fileExport: faFileExport,
};

Object.keys(renderFaIcons).forEach((faIcon) => {
	renderFaIcons[faIcon] = <FontAwesomeIcon icon={renderFaIcons[faIcon]} />;
});

export { renderFaIcons };
