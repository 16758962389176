export const DEFAULT_THEME = 'quartz';
export const THEMES_CLASSNAMES = {
	quartz: 'ag-theme-quartz',
	balham: 'ag-theme-balham',
	alpine: 'ag-theme-alpine',
	material: 'ag-theme-material',
};

export const ARRAY_COL_DEFS = {
	enableValue: false,
	enableRowGroup: false,
	enablePivot: false,
	sortable: false,
	filter: 'agSetColumnFilter',
	cellDataType: false,
	keyCreator: (params) => params.value,
};

export const ID_COL_DEFS = {
	filter: false,
	enableRowGroup: false,
	enablePivot: false,
	width: 120,
	allowedAggFuncs: ['count'],
};

