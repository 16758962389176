import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import t from 'i18n';
import { AutoAnimate } from '@smartplatform/ui';
import { Section, IconButton } from 'components';
import { renderFaIcons } from 'client/tools';
import { AmountRange } from './AmountRange';
import './amountRangeGroup.scss';
import { observable } from 'mobx';
import { CancelIconButton, ApproveIconButton } from 'components';
import AmountRangeStore from './amountRangeStore';
@inject('productStore')
@observer
export class AmountRangeGroup extends Component {
	constructor(props) {
		super(props);
		this.amountRangeStore = new AmountRangeStore(props.productStore);
	}

	render() {
		const { createRange, onSave, onCancel, showEditButtons, disableSave, amountRanges, notValidRanges } = this.amountRangeStore;
		return (
			<Section title={t('product.amountRanges')} className='amount-ranges'>
				<AutoAnimate className='records'>
					{amountRanges.map((range, index) => (
						<AmountRange
							key={index}
							range={range}
							index={index}
							amountRangeStore={this.amountRangeStore}
							notValidValues={notValidRanges.get(range)}
						/>
					))}
				</AutoAnimate>
				<div className='actions'>
					<IconButton icon={renderFaIcons.plus} onClick={createRange} />
					{showEditButtons && (
						<>
							<ApproveIconButton onClick={onSave} disabled={disableSave} />
							<CancelIconButton onClick={onCancel} />
						</>
					)}
				</div>
			</Section>
		);
	}
}

