import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import background from 'client/img/auth_background.png';
import store from 'client/store';
import t from 'i18n';

import './authLayout.scss';
import FeedbackBtn from 'components/feedback/FeedbackBtn';
import FeedbackPopup from 'components/feedback/FeedbackPopup';

@observer
export class AuthLayout extends Component {
	render() {
		return (
			<div className='auth-layout'>
				<div className='auth-background'>
					<img src={background} alt='background' />
					<div className='background-layout'></div>
					{/* <div className='slogan'>
						<div>
							<i>для движения</i>
						</div>
						<div>
							<strong>к большим целям</strong>
						</div>
					</div> */}
				</div>
				{this.props.children}
				<FeedbackBtn />
				{store.ui.showFeedbackPopup && <FeedbackPopup />}
			</div>
		);
	}
}
