import React from 'react';
import { Select } from '@smartplatform/ui';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import store from 'client/store';
import t from 'i18n';

export const ToolbarItemsSelect = observer((props) => {
	return <Select className='items-select' {...props} />;
});

