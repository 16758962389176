import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { renderFaIcons } from 'client/tools';
import { IconButton } from 'client/components/buttons';
import { ErrorMessage } from '../error-message';
import classNames from 'classnames';
import store from 'client/store';
import t from 'i18n';

import './fieldPassword.scss';

@observer
export class FieldPassword extends Component {
	@observable isPasswordVisible = false;

	constructor(props) {
		super(props);
	}

	togglePasswordVisible = () => {
		this.isPasswordVisible = !this.isPasswordVisible;
	};

	render() {
		const { className, label, error, isRequired, onChange, value, onKeyUp } = this.props;
		const addProps = {};
		if (onKeyUp) addProps.onKeyUp = onKeyUp;
		return (
			<div className={classNames('input-password field', className)}>
				<label className={classNames({ required: isRequired })}>{label}</label>
				<div className='password-field'>
					<input
						onChange={onChange}
						value={value}
						autoComplete='new-password'
						type={this.isPasswordVisible ? 'text' : 'password'}
						name='password'
						placeholder={t('password.input')}
						autoComplete='new-password'
						{...addProps}
					/>
					<IconButton
						noBorder
						disableRipple
						onClick={this.togglePasswordVisible}
						icon={this.isPasswordVisible ? renderFaIcons.eyeIcon : renderFaIcons.eyeSlashIcon}
						tabIndex={-1}
					/>
				</div>
				<ErrorMessage field={error} />
			</div>
		);
	}
}
