import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { ProductCard } from './product-card';
import './productList.scss';

@inject('productListStore')
@observer
export class ProductList extends Component {
	constructor(props) {
		super(props);
		this.priceListId = props.id;
	}

	render() {
		const { products } = this.props.productListStore;

		return (
			<div className='products-list'>
				{products.map((product) => (
					<ProductCard key={product.id} product={product} />
				))}
			</div>
		);
	}
}

