import EmailIcon from 'client/img/icons/source-email.svg';
import TelegramIcon from 'client/img/icons/source-telegram.svg';
import VKIcon from 'client/img/icons/source-vk.svg';
import WhatsAppIcon from 'client/img/icons/source-whatsApp.svg';

export const MESSAGE_PATH = '/messages';
export const MESSAGES_DIALOG_PATH = `${MESSAGE_PATH}/dialogs`;
export const MESSAGES_SETTINGS_PATH = `${MESSAGE_PATH}/settings`;

export const ICONS = {
	EMAIL: EmailIcon,
	TLG: TelegramIcon,
	VK: VKIcon,
	WA: WhatsAppIcon,
};
