import React from 'react';
import { ColorPicker, Field, RecordSelect, Tab, Tabs, Input } from '@smartplatform/ui';
import t from 'i18n';
import store from 'client/store';
import { ModelEdit } from 'components';
import { HasManyList } from './hasmany-list';
import { EXCLUDED_FIELDS } from './constants';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { getLabelName } from 'client/tools';
import { lowerFirstLetter } from '../../tools/stringHelpers';
@observer
export class Edit extends React.Component {
	@observable record;
	filter = {};
	static defaultProps = {
		editProperties: [],
		excludeProperties: [],
		excludeRelations: [],
	};

	@observable error = null;
	constructor(props) {
		super(props);
		this.modelName = props.modelName;
		this.path = props.path;
		this.model = store.model[this.modelName];
		this.id = parseInt(props.match.params.id);
		this.focusProperty = props.focusProperty;

		if (!this.model) {
			this.error = 'Invalid model/no read access';
		} else {
			store.ui.title = t(getLabelName('title', this.modelName));
			const { editProperties, excludeProperties, editRelations } = props;
			const { PROPERTIES, RELATIONS } = this.model;
			//properties убираются исключения , ключи реляций ИЛИ editProperties
			const modelForeignKeys = Object.values(RELATIONS).map(({ foreignKey }) => foreignKey);
			this.properties = Object.entries(PROPERTIES).reduce((acc, [key, value]) => {
				if (![...modelForeignKeys, ...EXCLUDED_FIELDS.filter((item) => !editProperties.includes(item))].includes(key)) {
					if (editProperties.length && !editProperties.includes(key)) {
						return acc;
					}
					acc.push({ ...value, name: key });
				}
				return acc;
			}, []);

			//rels belongsTo реляции без owner ИЛИ editRelations
			if (!editRelations) {
				this.relations = Object.keys(RELATIONS)
					.filter((relation) => RELATIONS[relation].name !== 'owner' && RELATIONS[relation].type === 'belongsTo')
					.map((relation) => ({ ...RELATIONS[relation], property: 'name' }));
			} else {
				this.relations = editRelations?.slice(0).map((relation) => ({ ...relation, ...RELATIONS[relation.relation] })) || [];
			}

			//filter - в инклюде реляции с филдами, в филдах поля проперти и ключи реляций
			this.filter.include = this.relations.map(({ name, properties = [], property }) => ({
				relation: name,
				scope: { fields: property || properties },
			}));
			this.filter.fields = [
				// айдишка чтобы подтягивать реляции hasMany/manyToMany
				'id',
				'isProtected',
				'isReadOnly',
				// филды пропов
				...this.properties.map(({ name }) => name),
				// ключи реляций belongsto
				...this.relations.filter(({ type }) => type === 'belongsTo').map(({ foreignKey }) => foreignKey),
			];
			if (PROPERTIES.externalId) this.filter.fields.push('externalId');
		}
	}

	//beforeDelete = () => this.props.getValidatedRemove?.(this.record);

	onAction = () => store.route.push({ path: this.props.path });

	getRecord = (record) => (this.record = record);

	render() {
		const { belongsTo = [], hasMany = [] } = this.relations.reduce((acc, relation) => {
			if (!acc[relation.type]) acc[relation.type] = [];
			acc[relation.type].push(relation);
			return acc;
		}, {});
		let { Component, disabled, excludeProperties, excludeRelations, noDeleteIfExternalId } = this.props;
		const { getRecord, onAction, path, filter, id, model, modelName } = this;

		const deleteLabel = `${lowerFirstLetter(modelName)}.delete`;
		const deleteTitle = t(deleteLabel) === deleteLabel ? t('delete') : t(deleteLabel);
		noDeleteIfExternalId = noDeleteIfExternalId && !!this.record?.externalId;
		const recordIsProtected = this.record?.isProtected === true;
		const disabledSave = disabled || this.record?.isReadOnly === true;
		const noDelete = disabled || noDeleteIfExternalId || recordIsProtected;

		const mainInfoForm = (
			<>
				<ModelEdit
					model={model}
					getRecord={getRecord}
					id={id}
					path={path}
					filter={filter}
					disabled={disabled}
					noSave={disabledSave}
					//beforeDelete={beforeDelete}
					noDelete={noDelete}
					deleteTitle={deleteTitle}
					onSave={onAction}
					onDelete={onAction}
				>
					{this.properties
						.filter((property) => !excludeProperties.includes(property.name))
						.map(({ type, name }, index) => {
							const label = getLabelName(name, modelName);
							let content;
							let isFocus = false;
							if (name === 'color') {
								content = <ColorPicker />;
							}
							if (name === this.focusProperty || index === 0) isFocus = true;
							return (
								<Field
									key={name}
									property={name}
									label={label}
									disabled={['updatedAt', 'createdAt'].includes(name)}
									autoFocus={isFocus && !this.id}
								>
									{content}
								</Field>
							);
						})}
					{belongsTo
						.filter((relation) => !excludeRelations.includes(relation.name))
						.map(({ name, property, computed, children }) => {
							const labelName = this.modelName.charAt(0).toLowerCase() + this.modelName.slice(1) + '.' + name;
							const noTranslate = t(labelName) === labelName;
							const label = noTranslate ? getLabelName('title', name) : t(labelName);

							return (
								<Field
									key={'relation_' + name + property}
									relation={name}
									property={property}
									computed={computed}
									label={label}
								>
									{children ? children : <RecordSelect />}
								</Field>
							);
						})}
				</ModelEdit>
				{!!id && Component && <Component id={id} />}
			</>
		);

		if (hasMany.length) {
			const currentPath = this.props.match.url;
			return (
				<Tabs>
					<Tab title={t('mainInfo')} path={currentPath} exact render={() => mainInfoForm} />
					{hasMany.map((relation, index) => (
						<Tab
							title={getLabelName(relation.name, modelName)}
							exact
							key={index}
							path={`${currentPath}/${relation.name}`}
							render={() => (
								<HasManyList
									relationInfo={relation}
									foreignKeyValue={id}
									properties={relation.properties}
									include={relation.include}
								/>
							)}
						/>
					))}
				</Tabs>
			);
		}

		return mainInfoForm;
	}
}
