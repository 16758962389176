import React from 'react';
import { observer } from 'mobx-react';
import { Notification } from './Notification';
import store from 'client/store';
import './notifications.scss';

@observer
export class Notifications extends React.Component {
	render() {
		const { notifications } = store.ui;
		if (!notifications.length) return null;

		return (
			<div className='notifications'>
				{notifications.map((notification) => (
					<React.Fragment key={notification.id}>
						<Notification {...notification} />
					</React.Fragment>
				))}
			</div>
		);
	}
}

