import React, { Component } from 'react';
import logoAuth from 'client/img/logo-auth.svg?url';
import './logo.scss';

export class Logo extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className='auth-logo'>
				<img src={'/api' + logoAuth} />
			</div>
		);
	}
}

