import { startOfDay } from 'date-fns';

/**
 * Хелпер позволяет фильтровать данные не точь-в-точь до секунды, а текущим днем. Например, Равно=15.10.2024 выдаст все записи за этот день.
 *
 */
export const getSameDateFilter = () => {
	return {
		comparator: (filterLocalDateAtMidnight, cellValue) => {
			if (!cellValue) return -1;
			const cellDate = startOfDay(new Date(cellValue));

			if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
				return 0;
			}
			if (cellDate < filterLocalDateAtMidnight) {
				return -1;
			}
			if (cellDate > filterLocalDateAtMidnight) {
				return 1;
			}
			return 0;
		},
	};
};

