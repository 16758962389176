import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Catalog } from './Catalog';
import { CategoryCreate } from 'client/modules/sales/catalog/category-list/category-create';
import { Product } from 'client/modules/sales/catalog/product';
import { withTitleAndLink } from 'client/tools/hoc/withTitleAndLink';
import t from 'i18n';

const CatalogRoute = ({ match }) => {
	const { path } = match;
	return (
		<Switch>
			<Route path={path} exact component={withTitleAndLink(Catalog, { title: t('catalog.title') })} />
			<Route path={`${path}/category/create`} exact component={withTitleAndLink(CategoryCreate, { title: t('catalog.title') })} />
			<Route path={`${path}/category/:categoryId`} component={withTitleAndLink(CategoryCreate, { title: t('catalog.title') })} />
			<Route path={`${path}/product/create`} component={withTitleAndLink(Product, { title: t('catalog.title') })} />
			<Route path={`${path}/product/:productId`} component={withTitleAndLink(Product, { title: t('catalog.title') })} />
		</Switch>
	);
};

export default CatalogRoute;
