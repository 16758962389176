import React from 'react';
import t from 'i18n';
import { inject, observer } from 'mobx-react';
import { Column, Loader } from '@smartplatform/ui';
import { BasicList } from 'components';
import store from 'client/store';
import { renderStatus } from 'client/tools';
import './style.scss';
import { ICONS } from 'client/modules/messages/constants';

@inject('store')
@observer
export class ListDialogs extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	renderSource = (source) => {
		const Icon = ICONS[source.code];
		return Icon ? <Icon /> : '-';
	};

	renderLastMessage = (record) =>
		record.lastMessage ? (
			<div className='wrapper-last-message' dangerouslySetInnerHTML={{ __html: this.formatLastMessage(record.lastMessage) }} />
		) : (
			'-'
		);

	formatLastMessage = (message) => {
		let _message = message.replace(/<img\s+(?:[^>]*?\s+)?src=("([^"]*)"|'([^']*)'|([^'"\s>]+))[^>]*>/g, '[изображение]');
		_message = _message.replace(/<br?[^>]+>/g, '\n').replace(/(<((?!a|\/a)[^>]+)>)/gi, '');
		return _message;
	};

	renderUserName = (record) => record.name || record.username;

	renderContact = (record) => record.email || record.phone;

	renderStatusCol = (record) => {
		return record.status ? renderStatus(record.status) : '-';
	};

	renderCountRecords = (record) => (
		<div className='wrapper-count-record'>
			<p>
				{t('task.plural')}:<span>{record.countTask}</span>
			</p>
			<p>
				{t('order.plural')}:<span>{record.countOrder}</span>
			</p>
			<p>
				{t('document.plural')}:<span>{record.countDocument}</span>
			</p>
		</div>
	);

	render() {
		const { whereFilter, isInit } = this.store;

		if (!isInit) return <Loader size={20} />;

		return (
			<div className='wrapper-list-dialog'>
				<BasicList
					model={store.model.ViewDialog}
					filter={{
						where: whereFilter,
						order: 'createdAt desc',
						include: [{ relation: 'source', fields: ['id', 'code'] }, 'status'],
					}}
					renderCustomToolbar={() => null}
				>
					<Column relation={'source'} label={t('dialog.source')} computed={this.renderSource} width={70} />
					<Column label={t('dialog.nikeName')} width={220} computed={this.renderUserName} />
					<Column label={t('dialog.contact')} width={160} computed={this.renderContact} />
					<Column label={t('dialog.lastMessage')} computed={this.renderLastMessage} />
					<Column label={t('dialog.countRecords')} computed={this.renderCountRecords} width={250} />
					<Column label={t('dialog.status')} computed={this.renderStatusCol} width={200} />
					<Column property='createdAt' label={t('dialog.date')} width={140} />
				</BasicList>
			</div>
		);
	}
}
