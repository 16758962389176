import { observable } from 'mobx';

export class MessagesWidgetStore {
	@observable messages = [];
	@observable record;
	@observable isLoadingMessage = true;

	refMessages = null;

	constructor(record, relationName) {
		this.record = record;
		this.relationName = relationName;
		this.init();
	}

	init = async () => {
		await this.fetchMessages();
	};

	fetchMessages = async () => {
		if (this.relationName && Object.keys(this.record.MODEL.INFO.relations).includes(this.relationName)) {
			this.messages = await this.record[this.relationName].find({
				order: 'createdAt ASC',
				include: ['owner', 'attachments'],
			});
		}
		this.isLoadingMessage = false;
	};

	messagesOnMount = (el) => {
		if (el) this.refMessages = el;
		this.scrollBottom();
	};

	scrollBottom = () => {
		if (this.messages.length && !!this.refMessages) this.refMessages.scrollTop = this.refMessages.scrollHeight;
	};

	onSubmitComment = async () => {
		this.fetchMessages();
	};

	onIntersection = (message) => {
		if (message.hasOwnProperty('hasRead') && !message.hasRead) {
			message.hasRead = true;
			message.save();
		}
	};
}
