import React from 'react';
import { observer } from 'mobx-react';
import { Popup, Form, Row, Field, RecordSelect } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { FIELDS, Suggestions } from 'components/contragents';
import { renderFaIcons } from 'client/tools';
import { observable } from 'mobx';
import './style.scss';

const CONTRAGENT_TYPES = {
	JUR: 'JUR',
	IND: 'IND',
};

@observer
export default class NewContragentPopup extends React.Component {
	@observable isINNValid = true;

	constructor(props) {
		super(props);
		this.contragent = new store.model.Contragent();
	}

	onSave = async (contragent) => {
		if (this.props.onSave) this.props.onSave(contragent);
	};

	onClose = () => {
		if (this.props.onClose) this.props.onClose(false);
	};

	checkExistINN = async (inn) => {
		const recordSearch = await store.model.Contragent.find({ where: { inn: inn }, limit: 1, fields: ['id'] });
		this.isINNValid = !Boolean(recordSearch[0]);
	};

	render() {
		const labelINN = !this.isINNValid ? (
			<>
				{t('contragents.card.inn')} <b className='warning-message'>{t('contragents.card.innExist')}</b>
			</>
		) : (
			t('contragents.card.inn')
		);

		let disabledForm = !this.contragent.typeId || !this.contragent.name || !this.isINNValid;
		if (this.contragent?.type?.code === CONTRAGENT_TYPES.JUR) {
			disabledForm = !this.contragent.typeId || !this.contragent.name || !this.contragent.inn || !this.isINNValid;
		}

		return (
			<Popup width={600} onClose={this.onClose} header={t('client.title')} className='fast-create-contragent'>
				<Form record={this.contragent} stay onSave={this.onSave} disableSave={disabledForm}>
					<div className='wrapper-additional-text'>
						{renderFaIcons.info}
						<p className='additional-text'>{t('order.contragent.fastCreate')}</p>
					</div>
					<Row>
						<Field relation='type' property='fullName' label={t('contragents.card.type')} isRequired>
							<RecordSelect />
						</Field>
						<Field property={FIELDS.INN} label={labelINN} isRequired={this.contragent?.type?.code === CONTRAGENT_TYPES.JUR}>
							<Suggestions
								onSuggestionClick={(suggestion) => this.checkExistINN(suggestion?.inn)}
								handleBlur={(inn) => this.checkExistINN(inn)}
							/>
						</Field>
					</Row>
					<Row className='full-width'>
						<Field property={FIELDS.NAME} label={t('contragents.card.name')} isRequired>
							<Suggestions onSuggestionClick={(suggestion) => this.checkExistINN(suggestion?.inn)} />
						</Field>
					</Row>
				</Form>
			</Popup>
		);
	}
}
