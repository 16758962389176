import { io } from 'socket.io-client';
import { triggerTaskNotification, triggerTaskMention } from 'components';
import { observable, observe } from 'mobx';

export class SocketStore {
	reconnectionDelay = 10000;
	@observable rooms = new Set();
	@observable isConnected = false;

	constructor(store) {
		this.store = store;
		this.socket = new io({
			reconnection: true,
			reconnectionDelay: this.reconnectionDelay,
			reconnectionDelayMax: this.reconnectionDelay,
		});

		this.store.socket = this.socket;
		this.socket.join = (room) => this.socket.emit('joinRoom', room);
		this.socket.leave = (room) => this.socket.emit('leaveRoom', room);
		observe(this.rooms, (event) => {
			const { type, newValue, oldValue } = event;
			if (type === 'add') {
				this.socket.join(newValue);
			} else if (type === 'delete') {
				this.socket.leave(oldValue);
			}
		});
		this.socket.on('connect', () => {
			this.isConnected = true;
			this.rooms.size > 0 && this.socket.join([...this.rooms]);
		});

		this.socket.on('disconnect', () => {
			this.isConnected = false;
		});
	}

	initSubscribe = () => {
		this.subscribeConnect();
		this.subscribeOnlineList();
		this.subscribeUserRoom();
	};

	setReconnectionDelay = (value) => {
		this.reconnectionDelay = value;
		this.socket.disconnect();
		this.socket = new io({
			reconnectionDelay: this.reconnectionDelay,
			reconnectionDelayMax: this.reconnectionDelay,
		});

		this.socket.connect();
	};

	setSocketParams = (params = {}) => {
		this.socket.disconnect();
		this.socket = new io(params);
		this.socket.connect();
	};

	subscribeConnect = () => {
		this.socket.on('online', (user) => {
			const { id } = user;
			user = new this.store.model.User(user);
			this.store.onlineUsersMap.set(id, user);

			// const isMyAccount = id === this.store.model.user?.id;
			// if (subscribe && !isMyAccount) {
			// 	triggerLogInNotification(user);
			// }
		});

		this.socket.on('offline', (id) => {
			this.store.onlineUsersMap.delete(id);
		});
	};

	subscribeOnlineList = () => {
		this.socket.on('online-list', (users) => {
			const usersMap = new Map();
			for (let user of users) {
				user = new this.store.model.User(user);
				usersMap.set(user.id, user);
			}
			this.store.onlineUsersMap = usersMap;
		});
	};

	subscribeUserRoom = () => {
		const userId = this.store.model.user?.id;
		if (userId) {
			this.socket.on('Task.userId', ({ task, action }) => {
				if (task.userId) {
					const isMyTaskResponse = task.userId === userId;
					const isMyAction = action.userId === userId;
					if (action.name === 'update' && isMyTaskResponse && !isMyAction) {
						triggerTaskNotification(task);
					}
				}
			});

			this.socket.on('Task.mention', ({ task, action }) => {
				const isMyAction = action.userId === userId;
				if (action.name === 'mention' && !isMyAction) {
					triggerTaskMention(task);
				}
			});

			this.socket.on('taskCommentMentioned', async ({ task, action }) => {
				const isMyAction = action.userId === userId;
				if (action.name === 'mention' && !isMyAction) {
					triggerTaskMention(task);
				}
			});
		}
	};
}

