import { observable } from 'mobx';
import store from 'client/store';
import { triggerSaveNotification } from 'components';

// server/boot/43-settings.js сюда должны быть продублированы ивенты при добавлении/удалении
export const NOTIFICATIONS = {
	Task: [
		'change',
		'comment',
		'label',
		'user',
		'description',
		'attachment',
		//  'deadline',
		'mentioned',
	],
	// Order: ['deadline'],
	// ProductionOrder: ['deadline'],
};

export const DATASOURCES = ['email', 'telegram'];

export class NotificationSettingsStore {
	@observable isLoading = true;
	@observable changedSettings = new Set();

	@observable notificationSettings = new Map(); // мапу заполняем в формате код - объект записи
	originalValues;

	constructor({ userId, defaultValue }) {
		this.userId = userId; // наличие юзера указывает на то, что это настройки конкретного пользователя
		this.defaultValue = defaultValue;
		this.init();
	}

	init = async () => {
		//  дефолтные настройки
		const settings = await store.model.Setting.find({ where: { ownerId: null } });
		settings.forEach((setting) => this.notificationSettings.set(setting.code, setting));

		// сверху накатываем настройки пользователя, если мы опускали айди в пропсах
		if (this.userId) {
			const settings = await store.model.Setting.find({ where: { ownerId: this.userId } });
			settings.forEach((setting) => this.notificationSettings.set(setting.code, setting));
		}

		this.setOriginalValues();
		this.isLoading = false;
	};

	onChange = (key, value) => {
		const record = new store.model.Setting({ code: key, value });
		// сохраняем айдишник записи, если это настройка юзера, т.е в ней есть ownerId, эту запись перезапишем в onSave
		if (this.notificationSettings.get(key)?.ownerId) {
			record.id = this.notificationSettings.get(key).id;
			record.ownerId = this.notificationSettings.get(key).ownerId;
		}
		this.notificationSettings.set(key, record);
		this.checkSettingChanged(key);
	};

	checkSettingChanged = (key) => {
		if (this.notificationSettings.get(key).value !== this.originalValues.get(key)) {
			this.changedSettings.add(key);
		} else {
			this.changedSettings.delete(key);
		}
	};

	get isChanged() {
		return this.changedSettings.size > 0;
	}

	// оригинальные значения в формате мапы код-значение
	setOriginalValues = () =>
		(this.originalValues = new Map(new Array(this.notificationSettings).map((setting) => [setting.code, setting.value])));

	onSave = async () => {
		const userId = this.userId || null;
		await store.model.Setting.batchUpdate({
			settings: [...this.changedSettings].map((key) => this.notificationSettings.get(key)),
			userId,
		});

		this.setOriginalValues();
		triggerSaveNotification();
		this.changedSettings.clear();
	};
}

