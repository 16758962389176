import { Column } from '@smartplatform/ui';
import store from 'client/store';
import { BasicList } from 'components';
import t from 'i18n';
import React from 'react';

export const List = ({ modelName, path, title }) => {
	return (
		<BasicList model={store.model[modelName]} path={path} title={title} disabledButton>
			<Column property='title' label={t('name')} />
			<Column property='code' label={t('code')} />
		</BasicList>
	);
};
