import React from 'react';
import './progressBar.scss';
import classNames from 'classnames';

export const ProgressBar = ({ percentage }) => {
	const className = classNames('progress-bar', { done: percentage === 100 });
	return (
		<div className='progress-bar-container'>
			<div className={className} style={{ width: `${percentage}%` }}></div>
		</div>
	);
};

