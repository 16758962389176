import React from 'react';
import { Button } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import './addButton.scss';

export class AddButton extends React.Component {
	render() {
		let { className, ...restProps } = this.props;
		className = classNames('add-button', className);
		return <Button className={className} endIcon={<FontAwesomeIcon icon={faPlusCircle} />} {...restProps} />;
	}
}

