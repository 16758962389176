import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { ImportButton } from '../buttons';

@observer
export class ToolbarImportBtn extends React.Component {
	static propTypes = {
		onClick: PropTypes.func,
	};

	render() {
		return <ImportButton onClick={this.props.onClick} {...this.props} />;
	}
}

