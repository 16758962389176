import React from 'react';
import appStore from 'client/store';
import { observer } from 'mobx-react';
import t from 'i18n';
import { LabelField } from 'components';
import './settings.scss';

@observer
export class SettingsToolPanel extends React.Component {
	onChange = (prop) => (value) => {
		appStore.local.agGrid[prop] = value;
		appStore.local.save();
		const { api } = this.props.gridRef.current;
		if (prop === 'leftSidebar') {
			api.setSideBarPosition(value ? 'left' : 'right');
		} else if (prop === 'floatingFilter') {
			const columnDefs = api.getColumnState();

			// Обновляем свойство floatingFilter для всех столбцов
			columnDefs.forEach((column) => {
				column.floatingFilter = value;
			});

			// Устанавливаем новую конфигурацию столбцов с обновленными floatingFilter
			api.applyColumnState({ state: columnDefs, applyOrder: true });
		}
	};

	render() {
		const agGridSettings = appStore.local.agGrid;
		return (
			<div className='settings-tool-panel'>
				<LabelField label={t('agGrid.theme')}>
					<div className='radio'>
						{['quartz', 'balham', 'material', 'alpine'].map((i) => (
							<label key={i}>
								<input
									type='radio'
									value={i}
									checked={agGridSettings.theme === i}
									onChange={(e) => this.onChange('theme')(e.target.value)}
								/>
								{i}
							</label>
						))}
					</div>
				</LabelField>
				{['leftSidebar', 'floatingFilter'].map((label) => (
					<label className={label} key={label}>
						<input
							type='checkbox'
							label={t('agGrid.' + label)}
							checked={agGridSettings[label]}
							onChange={(e) => this.onChange(label)(e.target.checked)}
						/>
						{t('agGrid.' + label)}
					</label>
				))}
			</div>
		);
	}
}

