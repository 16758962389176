import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable, toJS } from 'mobx';
import noImage from 'client/img/no-image.svg?url';
import { FeatureValueSelector } from './feature-value-selector';
import store from 'client/store';
import t from 'i18n';

import './productCard.scss';
import { DeleteIconButton } from 'components';

//todo фиксануть баг, при котором не у всех вариантов выставлено значение характеристики

@inject('productListStore')
@observer
export class ProductCard extends Component {
	@observable currentVariant = null;

	selectedFilters = {};
	nomenclatures = [];
	product = null;
	lastSelectedFeatureValue = null;
	prevLastSelectedFeatureValue = null;
	activeVariantsFeatureValues = {};

	constructor(props) {
		super(props);
		this.store = props.productListStore;
		this.product = props.product;
		this.availableFeatures = this.product.productNomenclaturesFeatures().map(({ featureId }) => featureId);
		this.nomenclatures = this.product.nomenclatures().map((variant) => {
			const variantFeaturesValues = variant
				.featuresValues()
				.filter(({ featureId, featureValueId }) => this.availableFeatures.includes(featureId) && featureValueId);
			variant.__availableFeaturesValues = [...variantFeaturesValues];
			return variant;
		});
	}

	componentDidMount() {
		this.init();
	}

	//*инициализируем первый вариант и фильтры исходя из выбранного варианта
	init = () => {
		this.currentVariant = this.nomenclatures.length > 0 ? this.nomenclatures[0] : null;
		if (this.currentVariant) {
			this.currentVariant.__availableFeaturesValues.forEach(({ feature, featureValue }) => {
				this.selectedFilters[feature.id] = featureValue;
			});
		}

		this.getActiveFeaturesValues();
	};

	setLastValueForTheFirstTime = (value) => {
		this.lastSelectedFeatureValue = value;
	};

	onSelect = async ({ feature, value, product }) => {
		this.prevLastSelectedFeatureValue = this.lastSelectedFeatureValue;
		this.lastSelectedFeatureValue = value;
		this.selectedFilters[feature.id] = value;

		this.findVariants();
	};

	//*ищем все варианты с текущими выбранными значениями характеристик
	findVariants = () => {
		//*массив с любыми вариантами, где имеется выбранное значение характеристики
		let variantsWithSelectedFeatureValue = [];
		const nomenclatures = this.nomenclatures.filter((variant) => {
			const variantFeaturesValues = variant.__availableFeaturesValues;

			let valid = true;
			for (const { feature, featureValue } of variantFeaturesValues) {
				if (this.selectedFilters[feature.id]?.id !== featureValue?.id) {
					valid = false;
				}

				if (featureValue.id === this.lastSelectedFeatureValue.id) {
					variantsWithSelectedFeatureValue = [...variantsWithSelectedFeatureValue, variant];
				}
			}
			return valid;
		});

		this.currentVariant = !!nomenclatures.length ? nomenclatures[0] : null;

		if (variantsWithSelectedFeatureValue.length && !this.currentVariant) {
			this.findVariantsWithSelectedValue(variantsWithSelectedFeatureValue);
		}

		this.getActiveFeaturesValues();
	};

	//* ищем среди вариантов с выбранной характеристикой те, у которых какие-нибудь значения характеристик совпадают с выбранными значениями характеристик (selectedFilters)
	findVariantsWithSelectedValue = (variants) => {
		let appropriateVariantWithPrevSelectedValue = null;
		let appropriateVariantWithEqualSelectedFiltersValue = null;

		variants.forEach((variant) => {
			const variantFeatureValues = variant.__availableFeaturesValues;

			for (const { feature, featureValue } of variantFeatureValues) {
				if (feature.id !== this.lastSelectedFeatureValue.feature.id) {
					if (this.prevLastSelectedFeatureValue?.id === featureValue.id) {
						appropriateVariantWithPrevSelectedValue = variant;
					}

					if (this.selectedFilters[feature.id]?.id === featureValue?.id) {
						appropriateVariantWithEqualSelectedFiltersValue = variant;
					}
				}
			}
		});

		this.currentVariant = appropriateVariantWithPrevSelectedValue || appropriateVariantWithEqualSelectedFiltersValue;

		//* если не находим подходящий вариант, то берем просто первый попавшийся
		if (!this.currentVariant) {
			this.currentVariant = variants[0];
		}

		this.currentVariant.__availableFeaturesValues.forEach(({ feature, featureValue }) => {
			this.selectedFilters[feature.id] = featureValue;
		});
	};

	//*получаем активные кнопки значений характеристик
	getActiveFeaturesValues = () => {
		const activeVariantsFeatureValues = {};

		const variants = this.getAppropriateVariants();

		variants.forEach((variant) => {
			const variantFeatureValues = variant.__availableFeaturesValues;
			for (const { feature, featureValue } of variantFeatureValues) {
				if (!activeVariantsFeatureValues[feature.id]) {
					activeVariantsFeatureValues[feature.id] = new Set();
				}

				activeVariantsFeatureValues[feature.id].add(featureValue.id);
			}
		});

		this.activeVariantsFeatureValues = activeVariantsFeatureValues;
	};

	//*получаем подходящие варианты
	getAppropriateVariants = () => {
		let variants = [];
		const _variants = new Map();

		//*алгоритм такой: проходим по всем выбранными значениям характеристик, исключаем характеристики по одной и ищем варианты с остальными значениями характеристик (кроме исключенной) : 1:A, 2:B, 3:C (сначала ислюкаем характеристику 1 и ищем варианты, у которых характеристика 2 = B, 3=C), следующий цикл делаем также, но исключаем уже 2 характеристику и ищем все варианты, у которых характеристика 1=A, 3=C и тд.
		//!лучше вынести на бэк, чтобы не грузить клиент в случае огромного количества выбора характеристик
		for (const featureId in this.selectedFilters) {
			this.nomenclatures.filter((variant) => {
				const variantFeatureValues = variant.__availableFeaturesValues;

				for (const { feature, featureValue } of variantFeatureValues) {
					if (feature.id !== parseInt(featureId)) {
						if (this.selectedFilters[feature.id]?.id !== featureValue?.id) {
							return false;
						}
					}
				}
				_variants.set(variant.id, variant);
			});
		}

		_variants.forEach((value) => {
			variants.push(value);
		});

		return variants;
	};

	//*получаем все существующие айди значений характеристик, для каждой характеристики
	getExistingFeaturesValuesIds = () => {
		const featureValues = this.nomenclatures.flatMap((variant) => {
			return variant.__availableFeaturesValues;
		});

		const existingFeaturesValuesIds = {};
		featureValues.forEach(({ feature, featureValue }) => {
			if (existingFeaturesValuesIds[feature.id]) {
				if (feature?.type === 'list') {
					existingFeaturesValuesIds[feature.id].add(featureValue.id);
				}
			} else {
				existingFeaturesValuesIds[feature.id] = new Set([featureValue.id]);
			}
		});

		return existingFeaturesValuesIds;
	};

	onProductLick = () => {
		const params = {};
		const { paramsCategoryId, path } = this.props.productListStore;
		if (paramsCategoryId) params.categoryParentId = paramsCategoryId;
		store.route.push({ path: `${path}/product/${this.product.id}`, params });
	};

	deleteProduct = async (product) => {
		await store.model.Product.deleteById(product.id);
		this.store.init();
	};

	render() {
		const { product: viewProduct, currentVariant, onProductLick } = this;

		const productFeatures = viewProduct.featureValues().map((item) => ({
			value: item.featureValue,
			featureName: item.name,
		}));
		const variantFeatures = viewProduct.productNomenclaturesFeatures().filter(({ featureId }) => featureId);
		const existingFeaturesValuesIds = this.getExistingFeaturesValuesIds();

		/*let image = currentVariant && currentVariant.image
			? currentVariant.downloadFile('image') +
			`?image=[{"resize":{"height":115}}]&v=${currentVariant.updatedAt}`
			: noImage*/

		let image;
		if (currentVariant && currentVariant?.image) {
			image = currentVariant.downloadFile('image') + `?image=[{"resize":{"height":115}}]&v=${currentVariant.updatedAt}`;
		} else if (viewProduct?.product && !!viewProduct?.product?.image) {
			image = viewProduct.product.downloadFile('image') + `?image=[{"resize":{"height":115}}]&v=${viewProduct.product.updatedAt}`;
		} else {
			image = noImage;
		}

		return (
			<div className='product-card' onClick={onProductLick}>
				<div className='product-info-variant-image'>
					<div className='variant-image'>
						<img src={image} alt='' />
					</div>
					<div className='product-info'>
						<span className='product-name'>{viewProduct.name}</span>
						{productFeatures.map(({ value, featureName }, i) => {
							let _value = value;
							if (value === 'true') {
								_value = t('yes');
							} else if (value === 'false') {
								_value = t('no');
							}
							return (
								<span key={i}>
									{featureName}: {_value}
								</span>
							);
						})}
						<span className='variants-count'>
							{t('product.nomenclaturesCount')}: {viewProduct.variantCount}
						</span>
						<span className='product-category'>{viewProduct?.category?.name}</span>
					</div>
				</div>
				<div className='features'>
					{variantFeatures.map(({ id, feature, featureValue }, i, array) => (
						<FeatureValueSelector
							key={id}
							product={viewProduct}
							feature={feature}
							featureValue={featureValue}
							onSelect={this.onSelect}
							firstVariant={currentVariant}
							enableFeatureValueIds={existingFeaturesValuesIds[feature.id]}
							selectedFilters={this.selectedFilters}
							activeVariantsFeatureValues={this.activeVariantsFeatureValues[feature.id]}
							isLastFeature={i === array.length - 1}
							setLastValueForTheFirstTime={this.setLastValueForTheFirstTime}
						/>
					))}
				</div>
				<div className='prices-and-controls'>
					<div className='delete-btn'>
						<DeleteIconButton onConfirm={() => this.deleteProduct(viewProduct)} />
					</div>
					{/*<div className='delimeter' />
					<div className='prices-list'>
						{currentVariant && (
							<div className='nomenclature-prices'>
								{currentVariant.prices().map((variantPrice) => {
									const { amountRange, price } = variantPrice;

									return (
										<div key={variantPrice.id} className='nomenclature-price'>
											<div className='range'>
												{t('from')} {amountRange?.amountFrom || '1'}
												&nbsp;
												{amountRange?.amountTo && (
													<>
														{t('to')} {amountRange.amountTo || <>&infin;</>}
													</>
												)}
											</div>
											<div className='price'>
												{price}
												&nbsp;
											</div>
										</div>
									);
								})}
							</div>
						)}
					</div>*/}
				</div>
			</div>
		);
	}
}
