import { observable } from 'mobx';
import store from 'client/store';
import { formatDate, getRoleNames } from 'client/tools';

export class OrderFormStore {
	@observable order;
	@observable isLoading = true;
	availableListIds = [];

	constructor(order, dialog) {
		this.order = order;
		this.dialog = dialog;
		this.init();
	}

	init = async () => {
		if (!store.isManagerOrAdmin) this.availableListIds = await store.model.ViewOrderList.getLists({ rolesNames: getRoleNames() });
		this.isLoading = false;
	};

	beforeSave = () => {
		const contactUser = this.dialog.name || this.dialog.username || this.dialog.phone || this.dialog.email;
		this.order.description = `${contactUser} ${formatDate(new Date(), 'dd.MM.yyyy HH:mm')}`;
	};
}
