import React from 'react';
import { Route, Switch } from 'react-router';
import { withTitleAndLink } from 'client/tools/hoc/withTitleAndLink';
import t from 'i18n';
import { Edit } from './Edit';
import { List } from './List';
const modelName = 'DialogSource';

export function DialogSources({ match }) {
	const { path } = match;
	return (
		<Switch>
			<Route
				path={`${path}`}
				exact
				component={withTitleAndLink(List, { title: t('dialogSource.plural'), componentProps: { match, modelName } })}
			/>
			<Route
				path={`${path}/page/:page`}
				component={withTitleAndLink(List, { title: t('dialogSource.plural'), componentProps: { match, modelName } })}
			/>
			<Route
				path={`${path}/:id`}
				component={withTitleAndLink(Edit, {
					title: t('dialogSource.title'),
					componentProps: { path, modelName },
				})}
			/>
		</Switch>
	);
}
