import React, { Component, createRef } from 'react';
import { inject, observer } from 'mobx-react';
import './suggestions.scss';
import { AutoAnimate } from '@smartplatform/ui';

@inject('productListStore')
@observer
export class Suggestions extends Component {
	store;

	ref;
	constructor(props) {
		super(props);
		this.store = props.productListStore;
	}

	render() {
		const { children } = this.props;
		const { suggestions, searchValue, onSuggestionSelect, isInputFocus, epmtyString } = this.store;

		const _searchValue = searchValue.trim();

		return (
			<AutoAnimate className='suggestions-wrapper'>
				{children}
				{isInputFocus && _searchValue.length > 0 && suggestions.length > 0 && (
					<div className='suggestions-popup'>
						{_searchValue.length > 0 && suggestions.length > 0 ? (
							suggestions.map((suggestion) => (
								<div className='suggestion-item' onMouseDown={() => onSuggestionSelect(suggestion)} key={suggestion.id}>
									{suggestion.name}
									<span className='group'>{suggestion.MODEL.name === 'ViewProduct' ? 'продукт' : 'категория'}</span>
								</div>
							))
						) : (
							<div className='suggestion-item empty'>{epmtyString}</div>
						)}
					</div>
				)}
			</AutoAnimate>
		);
	}
}

