import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import t from 'i18n';
import { Button } from './Button';
import { faFilter, faFilterCircleXmark, faUpload } from '@fortawesome/free-solid-svg-icons';
import ExportIcon from 'client/img/icons/export.svg';
import { renderFaIcons } from 'client/tools';

import './button.scss';

const makeButton =
	({ className, ...rest }) =>
	(props) =>
		<Button {...rest} {...props} className={classNames(props.className, className)} />;

export const CancelButton = (props) => {
	const Button = makeButton({ className: 'cancel-button', variant: 'default' });
	return <Button {...props} text={t('cancel')} />;
};

export const CleanButton = (props) => {
	const Button = makeButton({
		startIcon: <FontAwesomeIcon icon={faFilterCircleXmark} />,
		variant: 'default',
	});
	return <Button {...props} text={t('clean')} />;
};

export const ExportButton = (props) => {
	const Button = makeButton({ startIcon: <FontAwesomeIcon icon={faUpload} />, variant: 'default' });
	return <Button {...props} text={t('export')} />;
};

export const ImportButton = (props) => {
	const Button = makeButton({ startIcon: <ExportIcon />, variant: 'default' });
	return <Button {...props} text={t('import')} />;
};

export const FiltersButton = (props) => {
	const Button = makeButton({
		variant: 'default',
		startIcon: <FontAwesomeIcon icon={faFilter} />,
		className: 'filter-button',
	});
	return <Button {...props} text={t('filter.plural')} />;
};

export { Button } from './Button';
export { DeleteButton } from './DeleteButton';

export { AddButton } from './AddButton';
