import React from 'react';
import { observer } from 'mobx-react';
import { Avatar, Popup } from '@smartplatform/ui';
import { formatDate, fioShort } from 'client/tools';
import t from 'i18n';
import './style.scss';

@observer
export default class HistoryDeltaPopup extends React.Component {
	renderValue = (field, record) => {
		let value;
		if (record) {
			const { computedPropsFunctions } = this.props;
			const nameRelation = Object.values(record.MODEL.RELATIONS).find((relation) => relation.foreignKey === field)?.name;
			if (nameRelation) {
				value = Object.keys(computedPropsFunctions || {})?.some((i) => i === field)
					? computedPropsFunctions[field](record)
					: record[nameRelation]?.name;
			} else {
				value = Object.keys(computedPropsFunctions || {})?.some((i) => i === field)
					? computedPropsFunctions[field](record)
					: record[field];
			}
		}
		return value || '-';
	};

	render() {
		const { onClose, log, oldLog, baseModelRelationName, field } = this.props;
		const { record } = log;
		const { renderValue } = this;

		const _fields = field ? [field] : record.updated;

		return (
			<Popup onClose={onClose} className='history-delta-popup'>
				<div className='owner'>
					<Avatar user={record.owner} size={24} /> {fioShort(record.owner)}
					<span className='date'>{formatDate(record.createdAt, 'dd.MM.yyyy HH:mm')}</span>
				</div>

				<table>
					<thead>
						<tr>
							<th width='50%'>{t('history.deltaPopup.old')}</th>
							<th width='50%'>{t('history.deltaPopup.new')}</th>
						</tr>
					</thead>
					<tbody>
						{_fields.map((updatedField, i) => (
							<React.Fragment key={i}>
								<tr>
									<td colSpan={2}>{t(`${baseModelRelationName}.` + updatedField)}</td>
								</tr>
								<tr>
									<td>{renderValue(updatedField, oldLog)}</td>
									<td>{renderValue(updatedField, record)}</td>
								</tr>
							</React.Fragment>
						))}
					</tbody>
				</table>
			</Popup>
		);
	}
}
