import React from 'react';
import t from 'i18n';
import './notificationSettings.scss';
import { DATASOURCES, NOTIFICATIONS, NotificationSettingsStore } from './notificationSettingsStore';
import { observer } from 'mobx-react';
import { Button, Switch } from 'components';
import PropTypes from 'prop-types';
import store from 'client/store';
import { TwoFactor } from 'components/two-factor';
import { lowerFirst } from 'lodash';
import { Select } from '@smartplatform/ui';

@observer
export class NotificationSettings extends React.Component {
	static propTypes = {
		userId: PropTypes.number,
	};

	constructor(props) {
		super(props);
		this.notificationSettingsStore = new NotificationSettingsStore({ userId: props.userId });
	}

	render() {
		const { isLoading, onSave, isChanged, notificationSettings, onChange } = this.notificationSettingsStore;
		if (isLoading) return null;
		return (
			<div className='user-settings'>
				<div className='notification-settings'>
					{Object.keys(NOTIFICATIONS).map((model) => {
						const modelLowerName = lowerFirst(model);
						return (
							<div className='model-settings'>
								<h2 className='model-name'>{t(modelLowerName + '.plural')}</h2>
								<table className='sp-table'>
									<thead>
										<th className='property-name'>{t('name')}</th>
										{DATASOURCES.map((dataSource) => (
											<th>{t(`${dataSource}.title`)}</th>
										))}
									</thead>
									<tbody>
										{NOTIFICATIONS[model].map((code) => (
											<tr className='notification'>
												<td>{t(`notification.${modelLowerName}.${code}`)}</td>
												{DATASOURCES.map((dataSource) => {
													const key = `notification.${model}.${code}.${dataSource}`;
													return (
														<td>
															<Switch
																onChange={(value) => onChange(key, value ? 'true' : 'false')}
																value={notificationSettings.get(key)?.value === 'true'}
															/>
														</td>
													);
												})}

												{code === 'deadline' && (
													<td>
														<Select
															isRequired
															value={notificationSettings.get(`notification.${model}.${code}.timeout`)?.value}
															items={[
																{ value: String(15 * 60 * 60), label: t('timePeriod.15m') },
																{ value: String(1 * 60 * 60 * 60), label: t('timePeriod.1h') },
																{ value: String(3 * 60 * 60 * 60), label: t('timePeriod.3h') },
																{ value: String(6 * 60 * 60 * 60), label: t('timePeriod.6h') },
																{ value: String(12 * 60 * 60 * 60), label: t('timePeriod.12h') },
																{ value: String(24 * 60 * 60 * 60), label: t('timePeriod.24h') },
															]}
															onChange={(value) => onChange(`notification.${model}.${code}.timeout`, value)}
														/>
													</td>
												)}
											</tr>
										))}
									</tbody>
								</table>
							</div>
						);
					})}
					<div className='actions'>
						<Button disabled={!isChanged} onClick={onSave}>
							{t('notification.save')}
						</Button>
					</div>
				</div>
				{this.props.userId === store.model.user.id && <TwoFactor userId={this.props.userId} />}
			</div>
		);
	}
}

