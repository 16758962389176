import React from 'react';
import { observable } from 'mobx';
import { Logo } from '../components/logo/Logo';
import { Button } from '../../buttons';
import { observer } from 'mobx-react';
import t from 'i18n';
import store from 'client/store';
import { ErrorMessage } from '../components/error-message/ErrorMessage';

@observer
export class Restore extends React.Component {
	@observable email = '';
	@observable error = null;
	@observable sended = false;

	onEmailChange = (e) => {
		this.email = e.target.value;
	};

	back = () => store.route.push({ path: '/login' });

	onSubmit = async () => {
		this.error = null;
		try {
			await store.model.User.restorePassword(this.email);
			this.sended = true;
		} catch (e) {
			this.error = t(e.code);
		}
	};

	render() {
		return (
			<>
				<form className='auth-form'>
					<h3>{t('auth.restore')}</h3>
					{this.sended ? (
						<span className='mt-4 mb-4'>{t('user.recoverEmailSended')}</span>
					) : (
						<div className='field'>
							<label>{t('user.email')}</label>
							<input type='text' name='email' placeholder='Введите E-mail' value={this.email} onChange={this.onEmailChange} />
						</div>
					)}
					{this.error && <ErrorMessage field={this.error} />}
					<div className='submit'>
						<Button variant='default' onClick={this.back} text={t('auth.goBack')} />
						{!this.sended && <Button text={t('recover')} onClick={this.onSubmit} disabled={!this.email.length} />}
					</div>
				</form>
			</>
		);
	}
}
