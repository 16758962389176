import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import t from 'i18n';
import { BasicList, ModelEdit } from 'components';
import store from '@appStore';
import './customModelProperties.scss';
import { Column, Field, Select } from '@smartplatform/ui';
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import { observable } from 'mobx';
import { lowerFirstLetter } from '../../tools/stringHelpers';

const MODELS = ['Project', 'Task', 'User', 'Document', 'Order'];
const MODEL_MODULE_MAP = {
	Project: 'management',
	Task: 'management',
	Document: 'documents',
	Order: 'sales',
};

@observer
export class CustomModelProperties extends React.Component {
	static propTypes = {
		modelName: PropTypes.string,
	};

	static defaultProps = {};

	render() {
		const { modelName, match } = this.props;
		const { path } = match;
		return (
			<Switch>
				<Route path={path} exact render={(props) => <List {...props} modelName={modelName} />} />
				<Route path={`${path}/:id`} render={(props) => <Edit {...props} path={path} modelName={modelName} />} />
			</Switch>
		);
	}
}

const List = ({ modelName }) => (
	<BasicList model={store.model.ModelProperty} filter={{ where: { modelName } }}>
		<Column property='name' label={t('modelProperty.name')} />
		<Column property='description' label={t('modelProperty.description')} />
		<Column property='type' label={t('modelProperty.type')} computed={(r) => t('dataType.' + r.type?.toLowerCase())} />
		<Column property='priority' label={t('modelProperty.priority')} />
		<Column property='hasIndex' label={t('modelProperty.hasIndex')} />
		<Column property='isRequired' label={t('modelProperty.isRequired')} />
		<Column property='isUnique' label={t('modelProperty.isUnique')} />
		<Column property='isHidden' label={t('modelProperty.isHidden')} />
	</BasicList>
);

@observer
class Edit extends React.Component {
	@observable record = {};

	constructor(props) {
		super(props);
		this.RELATION_MODELS = MODELS.filter((modelName) => {
			if (modelName === 'User') return true;
			if (store.availableModules.some((module) => module.code === MODEL_MODULE_MAP[modelName])) return true;
		});
	}
	getRecord = (record) => {
		this.record = record;
		record.modelName = this.props.modelName;
		// дефолтный тип
		record.type = 'String';
	};
	onSave = async () => {
		await store.model.connect();
		store.route.push({ path: this.props.path });
	};
	render() {
		const { path, match } = this.props;
		const { getRecord, onSave, record } = this;
		const id = Number.isNaN(parseInt(match.params.id)) ? undefined : parseInt(match.params.id);
		const isNew = !this.record.id;

		return (
			<ModelEdit
				id={id}
				path={path}
				model={store.model.ModelProperty}
				getRecord={getRecord}
				onSave={onSave}
				checkRequiredFields={false}
				disableSave={!record.name || !record.type}
			>
				<Field property='name' label={t('modelProperty.name')} disabled={!isNew} />
				<Field property='description' label={t('modelProperty.description')} />
				<Field property='type' label={t('modelProperty.type')} isRequired disabled={!isNew}>
					<Select
						items={[
							...['Boolean', 'String', 'Number', 'Date', 'Datetime'].map((i) => ({
								value: i,
								label: t('dataType.' + i.toLowerCase()),
							})),
							...this.RELATION_MODELS.map((modelName) => ({
								value: modelName,
								label: t(lowerFirstLetter(modelName) + '.title'),
							})),
						]}
						isRequired
					/>
				</Field>
				<Field property='priority' label={t('modelProperty.priority')} />
				{['hasIndex', 'isRequired', 'isUnique', 'isHidden'].map((property) => (
					<Field property={property} label={t('modelProperty.' + property)} disabled={!isNew} key={property} />
				))}
			</ModelEdit>
		);
	}
}

