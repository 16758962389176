export const AG_GRID_LOCALE_RU = {
	sortAscending: 'Сортировка по возрастанию',
	sortDescending: 'Сортировка по убыванию',
	sortUnSort: 'Убрать сортировку',
	autosizeThisColumn: 'Автоматическая задать размер столбцу',
	columnChooser: 'Выбрать столбцы',
	addToValues: 'Добавить ${variable} в агрегацию',
	addToLabels: 'Добавить ${variable} в столбцы',
	removeFromValues: 'Убрать ${variable} из агрегации',
	removeFromLabels: 'Убрать ${variable} из столбцов',

	first: 'Первый',
	last: 'Последний',

	// Выбор фильтра
	selectAll: '(Выделить все)',
	selectAllSearchResults: '(Выделить все результаты поиска)',
	searchOoo: 'Поиск...',
	blanks: '(Пусто)',
	noMatches: 'Нет совпадений',

	// Числовой фильтр & текстовый фильтр
	filterOoo: 'Фильтрация...',
	equals: 'Равно',
	notEqual: 'Не равно',
	empty: 'Выберите один',
	before: 'До',
	after: 'После',
	blank: 'Не заполнено',
	notBlank: 'Заполнено',
	// Числовой фильтр
	lessThan: 'Меньше, чем',
	greaterThan: 'Больше, чем',
	lessThanOrEqual: 'Меньше или равно',
	greaterThanOrEqual: 'Больше или равно',
	inRange: 'В промежутке',
	inRangeStart: 'от',
	inRangeEnd: 'до',

	// Текстовый фильтр
	contains: 'Содержит',
	notContains: 'Не содержит',
	startsWith: 'Начинается с',
	endsWith: 'Кончается на',
	cut: 'Вырезать',
	// Фильтр даты
	dateFormatOoo: 'dd-mm-yyyy',

	// Условия фильтрации
	andCondition: 'И',
	orCondition: 'ИЛИ',

	// Кнопки фильтра
	applyFilter: 'Применить',
	resetFilter: 'Сбросить',
	clearFilter: 'Очистить',
	cancelFilter: 'Отменить',

	// Заголовки фильтра
	textFilter: 'Текстовый фильтр',
	numberFilter: 'Числовой фильтр',
	dateFilter: 'Фильтр по дате',
	setFilter: 'Выбрать фильтр',

	// Боковая панель
	columns: 'Столбцы',
	filters: 'Фильтры',

	// Панель инструментов столбцов
	pivotMode: 'Режим сводной таблицы',
	groups: 'Группы строк',
	rowGroupColumnsEmptyMessage: 'Перетащите столбец для группировки по строкам',
	values: 'Агрегация',
	valueColumnsEmptyMessage: 'Перетащите столбец для агрегации',
	pivots: 'Заголовки столбцов',
	pivotColumnsEmptyMessage: 'Перетащите столбец для задания заголовков столбцам',

	// Заголовок группы столбцов по умолчанию
	group: 'Группа',

	// Другое
	loadingOoo: 'Загрузка...',
	noRowsToShow: 'Нет данных',
	enabled: 'Включено',

	// Меню
	pinColumn: 'Закрепить столбец',
	pinLeft: 'Закрепить слева',
	pinRight: 'Закрепить справа',
	noPin: 'Не закреплять',
	valueAggregation: 'Агрегация по значению',
	autosizeThiscolumn: 'Автоматически задавать размер этого столбца',
	autosizeAllColumns: 'Автоматически задавать размер всем столбцам',
	groupBy: 'Группировать по',
	ungroupBy: 'Разгруппировать по',
	ungroupAll: 'Разгруппировать все',
	resetColumns: 'Сбросить столбцы',
	expandAll: 'Развернуть все',
	collapseAll: 'Свернуть все',
	copy: 'Копировать',
	ctrlC: 'Ctrl+C',
	copyWithHeaders: 'Копировать с заголовками',
	copyWithGroupHeaders: 'Копировать с заголовками групп',
	paste: 'Вставить',
	ctrlV: 'Ctrl+V',
	export: 'Экспорт',
	csvExport: 'Экспорт в CSV (.csv)',
	excelExport: 'Экспорт в Excel (.xlsx)',
	excelXmlExport: 'Экспорт в XML (.xml)',

	// Агрегирование корпоративного меню и строки состояния
	sum: 'Сумма',
	min: 'Минимум',
	max: 'Максимум',
	none: 'Пусто',
	count: 'Количество',
	avg: 'Среднее значение',
	filteredRows: 'Отфильтровано',
	selectedRows: 'Выбрано',
	totalRows: 'Всего строк',
	totalAndFilteredRows: 'Строки',
	more: 'многих',
	to: 'из',
	of: 'по',
	page: 'Страница',
	nextPage: 'Следующая страница',
	lastPage: 'Последняя страница',
	firstPage: 'Первая страница',
	previousPage: 'Предыдущая страница',
	pageSizeSelectorLabel: 'Количество записей на страницу',

	// Корпоративное меню (графики)
	pivotChartAndPivotMode: 'Сводная диаграмма & режим сведения',
	pivotChart: 'Сводная диаграмма',
	chartRange: 'Диапазон диаграммы',

	columnChart: 'Столбиковая диаграмма',
	groupedColumn: 'Сгруппированная',
	stackedColumn: 'Сложенная',
	normalizedColumn: '100% Сложенная',

	barChart: 'Панель',
	groupedBar: 'Сгруппированная',
	stackedBar: 'Сложенная',
	normalizedBar: '100% Сложенная',

	pieChart: 'Круговая диаграмма',
	pie: 'Круговая диаграмма',
	doughnut: 'Кольцевая диаграмма',

	line: 'Линия',

	xyChart: 'X Y (Разброс)',
	scatter: 'Диаграмма рассеяния',
	bubble: 'Пузырьковая диаграмма',

	areaChart: 'Область',
	area: 'Диаграмма с областями',
	stackedArea: 'Сложенная',
	normalizedArea: '100% Сложенная',

	histogramChart: 'Гистограмма',

	// Графики
	pivotChartTitle: 'Сводная диаграмма',
	rangeChartTitle: 'График диапазона',
	settings: 'Настройки',
	data: 'Данные',
	format: 'Формат',
	categories: 'Категории',
	defaultCategory: '(Пусто)',
	series: 'Серии',
	xyValues: 'X Y Значения',
	paired: 'Парный режим',
	axis: 'Ось',
	navigator: 'Навигация',
	color: 'Цвет',
	thickness: 'Толщина',
	xType: 'X Тип',
	automatic: 'Автоматически',
	category: 'Категория',
	number: 'Число',
	time: 'Время',
	xRotation: 'X Поворот',
	yRotation: 'Y Поворот',
	ticks: 'Отметки',
	width: 'Ширина',
	height: 'Высота',
	length: 'Длина',
	padding: 'Внутренний отступ',
	spacing: 'Отступ',
	chart: 'Диаграмма',
	title: 'Заголовок',
	titlePlaceholder: 'Заголовок диаграммы - двойной щелчок для редактирования',
	background: 'Фон',
	font: 'Шрифт',
	top: 'Верх',
	right: 'Право',
	bottom: 'Низ',
	left: 'Лево',
	labels: 'Метки',
	size: 'Размер',
	minSize: 'Минимальный размер',
	maxSize: 'Максимальный размер',
	legend: 'Легенда',
	position: 'Позиция',
	markerSize: 'Размер маркера',
	markerStroke: 'Обводка маркера',
	markerPadding: 'Внутренний отступ маркера',
	itemSpacing: 'Расстояние между предметами',
	itemPaddingX: 'Внутренний отступ предмета по X',
	itemPaddingY: 'Внутренний отступ предмета по Y',
	layoutHorizontalSpacing: 'Горизонтальный отступ',
	layoutVerticalSpacing: 'Вертикальный отступ',
	strokeWidth: 'Ширина обводки',
	offset: 'Смещение',
	offsets: 'Смещения',
	tooltips: 'Всплывающие подсказки',
	callout: 'Вызов',
	markers: 'Маркеры',
	shadow: 'Тень',
	blur: 'Размытие',
	xOffset: 'Смещение по X',
	yOffset: 'Смещение по Y',
	lineWidth: 'Ширина линии',
	normal: 'Нормальный',
	bold: 'Жирный',
	italic: 'Наклоненный',
	boldItalic: 'Жирный наклоненный',
	predefined: 'Предопределенный',
	fillOpacity: 'Непрозрачность заливки',
	strokeOpacity: 'Непрозрачность линии',
	histogramBinCount: 'Количество сегментов',
	columnGroup: 'Столбец',
	barGroup: 'Панель',
	pieGroup: 'Круговая',
	lineGroup: 'Линейная',
	scatterGroup: 'X Y (Разброс)',
	areaGroup: 'Зональная',
	histogramGroup: 'Гистограмма',
	groupedColumnTooltip: 'Сгруппированная',
	stackedColumnTooltip: 'Сложенная',
	normalizedColumnTooltip: '100% Сложенная',
	groupedBarTooltip: 'Сгруппированная',
	stackedBarTooltip: 'Сложенная',
	normalizedBarTooltip: '100% Сложенная',
	pieTooltip: 'Круговая',
	doughnutTooltip: 'Кольцевая',
	lineTooltip: 'Линейная',
	groupedAreaTooltip: 'Зональная',
	stackedAreaTooltip: 'Сложенная',
	normalizedAreaTooltip: '100% Сложенная',
	scatterTooltip: 'Рассеяния',
	bubbleTooltip: 'Пузырьковая',
	histogramTooltip: 'Гистограмма',
	noDataToChart: 'Нет данных для представления в виде диаграммы.',
	pivotChartRequiresPivotMode: 'Для сводной диаграммы необходимо включить режим сводной диаграммы.',
	chartSettingsToolbarTooltip: 'Меню',
	chartLinkToolbarTooltip: 'Связать с сеткой',
	chartUnlinkToolbarTooltip: 'Не связывать с сеткой',
	chartDownloadToolbarTooltip: 'Загрузить диаграмму',

	// ARIA
	ariaHidden: 'скрытый',
	ariaVisible: 'видимый',
	ariaChecked: 'проверенный',
	ariaUnchecked: 'непроверенный',
	ariaIndeterminate: 'неопределенный',
	ariaColumnSelectAll: 'Переключить на выделение всех столбцов',
	ariaInputEditor: 'Редактор ввода',
	ariaDateFilterInput: 'Ввод фильтра даты',
	ariaFilterInput: 'Ввод фильтра',
	ariaFilterColumnsInput: 'Ввод фильтра столбцов',
	ariaFilterValue: 'Значение фильтра',
	ariaFilterFromValue: 'Фильтровать от значения',
	ariaFilterToValue: 'Фильтровать до значения',
	ariaFilteringOperator: 'Оператор фильтрации',
	ariaColumnToggleVisibility: 'переключить видимость столбца',
	ariaColumnGroupToggleVisibility: 'переключить видимость группы столбцов',
	ariaRowSelect: 'Нажмите ПРОБЕЛ для выделения данной строки',
	ariaRowDeselect: 'Нажмите ПРОБЕЛ для снятия выделения данной строки',
	ariaRowToggleSelection: 'Нажмите ПРОБЕЛ, чтобы переключить выделение строки',
	ariaRowSelectAll: 'Нажмите ПРОБЕЛ, чтобы переключить выделение всех строк',
	ariaSearch: 'Поиск',
	ariaSearchFilterValues: 'Поиск значений по фильтру',
};

