import React, { useContext } from 'react';
import List from './icons/mode-list.svg';
import Kanban from './icons/mode-kanban.svg';
import Tiles from './icons/mode-tiles.svg';
import Calendar from './icons/mode-calendar.svg';
import Report from './icons/mode-report.svg';
import Gantt from 'client/img/mode-gantt.svg';
import { MODES } from 'client/constants';
import './style.scss';
import classNames from 'classnames';

const ModeContext = React.createContext(null);

const makeModeIcon = (Component) => {
	const ModeIcon = () => {
		const { mode, onChange } = useContext(ModeContext);
		const componentModeName = Component.displayName;
		const onClick = () => onChange(componentModeName);
		const className = classNames('icon-wrapper', mode === componentModeName ? 'active' : '');
		return (
			<div className={className}>
				<Component onClick={onClick} />
			</div>
		);
	};

	return ModeIcon;
};

export const Mode = ({ mode, onChange, children }) => {
	return (
		<ModeContext.Provider value={{ mode, onChange }}>
			<div className='modes'>{children}</div>
		</ModeContext.Provider>
	);
};

List.displayName = MODES.LIST;
Kanban.displayName = MODES.KANBAN;
Tiles.displayName = MODES.TILES;
Calendar.displayName = MODES.CALENDAR;
Report.displayName = MODES.REPORT;
Gantt.displayName = MODES.GANTT;

Mode.List = makeModeIcon(List);
Mode.Kanban = makeModeIcon(Kanban);
Mode.Tiles = makeModeIcon(Tiles);
Mode.Calendar = makeModeIcon(Calendar);
Mode.Report = makeModeIcon(Report);
Mode.Gantt = makeModeIcon(Gantt);
