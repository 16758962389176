import React from 'react';
import { Avatar } from '@smartplatform/ui';
import store from 'client/store';
import { processText, relative } from 'client/tools';
import classNames from 'classnames';
import './message.scss';
import { CustomAttachment } from 'components';

export class Message extends React.Component {
	constructor(props) {
		super(props);
		this.elementRef = React.createRef();
		this.intersectionObserver = null;
	}

	handleIntersection = (entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				if (this.props.onIntersection) this.props.onIntersection();
			}
		});
	};

	componentDidMount() {
		this.intersectionObserver = new IntersectionObserver(this.handleIntersection, { threshold: 0.5 });
		if (this.elementRef.current) {
			this.intersectionObserver.observe(this.elementRef.current);
		}
	}

	componentWillUnmount() {
		if (this.elementRef.current) {
			this.intersectionObserver.unobserve(this.elementRef.current);
		}
	}

	render() {
		const { record, noOwnerAvatar } = this.props;

		const attachments = record.attachments();

		if (!record || (!record.text && !attachments.length)) return null;

		const { owner } = record;

		return (
			<div className={classNames('wrapper-message', { 'is-me': record.ownerId })} ref={this.elementRef}>
				{!record.ownerId && noOwnerAvatar ? (
					<div className='wrapper-avatar-message'>{noOwnerAvatar}</div>
				) : (
					<Avatar user={owner} size={32} />
				)}
				<div className='triangle-message' />
				<div className='content-message'>
					<div className='message' dangerouslySetInnerHTML={{ __html: processText(record.text) }} />
					<span className='date'>{relative(record.createdAt)}</span>
					{!!attachments.length && (
						<div className='wrapper-attachments'>
							{attachments.map((record, index) => (
								<CustomAttachment attachment={record} noDelete={true} noOwner={true} key={index} />
							))}
						</div>
					)}
				</div>
			</div>
		);
	}
}
