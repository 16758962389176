import React, { Component } from 'react';
import classNames from 'classnames';
import Next from '../../img/next-switcher.svg';
import Prev from '../../img/prev-switcher.svg';
import { Button } from '@smartplatform/ui';

export class ToolbarPrevNextSwitcher extends Component {
	constructor(props) {
		super(props);
	}

	onPrev = () => {
		if (this.props.onPrev) this.props.onPrev();
	};

	onNext = () => {
		if (this.props.onNext) this.props.onNext();
	};

	render() {
		const { value, className } = this.props;
		const { onNext, onPrev } = this;
		const _className = classNames('next-prev-switcher', className);

		return (
			<div className={_className}>
				<Button className='control-switcher' onClick={onPrev}>
					<Prev />
				</Button>
				<div className='value-switcher'>{value}</div>
				<Button className='control-switcher' onClick={onNext}>
					<Next />
				</Button>
			</div>
		);
	}
}
