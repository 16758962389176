import React from 'react';
import { inject, observer } from 'mobx-react';
import DialogCard from 'client/modules/messages/dialogs/dialogs/kanban/card/DialogCard';
import { Kanban } from '@smartplatform/ui';
import t from 'i18n';
import { MESSAGES_DIALOG_PATH } from 'client/modules/messages/constants';
import { KanbanSkeleton } from 'components';
import './kanban.scss';

@inject('store')
@observer
export class KanbanDialogs extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	renderDialog = (dialog, status, options) => <DialogCard dialog={dialog} status={status} options={options} />;

	render() {
		const { statuses, onChangeKanban, gotoItemKanban, getKanbanInstance, hiddenListsKanban, kanbanInit } = this.store;

		if (!kanbanInit) return <KanbanSkeleton />;
		if (!statuses.length) return t('dialogStatus.notFound');

		const dialogs = {
			name: t('dialog.plural'),
			lists: statuses.map((status) => {
				return {
					id: status.id,
					name: status.name,
					color: status.color,
					totalCount: status.totalCount,
					data: status,
					items: status.dialogs().map((dialog) => {
						return {
							id: dialog.id,
							name: dialog.name,
							listId: dialog.statusId,
							path: `${MESSAGES_DIALOG_PATH}/${dialog.id}`,
							data: dialog,
						};
					}),
				};
			}),
		};

		return (
			<div className='wrapper-kanban-dialogs'>
				<Kanban
					project={dialogs}
					renderItem={this.renderDialog}
					itemClassName='dialog-kanban-item'
					onChange={onChangeKanban}
					gotoItem={gotoItemKanban}
					getInstance={getKanbanInstance}
					itemsLimit={20}
					hiddenLists={hiddenListsKanban}
				/>
			</div>
		);
	}
}
