import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@smartplatform/ui';
import { Ripple } from '../ripple-effect';
import classNames from 'classnames';
import './iconButton.scss';

export const IconButton = ({
	icon,
	endText,
	title,
	startText,
	model,
	noBorder,
	className,
	disableRipple,
	variant = 'default',
	...restProps
}) => {
	let icon_ = <div className='border-icon'>{icon}</div>;
	if (noBorder) {
		icon_ = icon;
	}

	if (startText && !React.isValidElement(startText)) {
		startText = <span>{startText}</span>;
	}
	if (endText && !React.isValidElement(endText)) {
		endText = <span>{endText}</span>;
	}

	const _className = classNames('icon-btn', className, {
		'no-border': noBorder,
		border: !noBorder,
		'with-text': endText || startText,
	});

	return (
		<Button className={_className} variant={variant} {...restProps} title={title}>
			{startText}
			{icon_}
			{endText}
			{!disableRipple && <Ripple />}
		</Button>
	);
};

IconButton.propTypes = {
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	icon: PropTypes.node,
	model: PropTypes.string,
	variant: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.oneOf(['small', 'default', 'large', 'sm', 'md', 'lg']), PropTypes.number]),
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

