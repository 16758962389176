import qs from 'qs';
import { createBrowserHistory } from 'history';
import { Rest, ModelStore } from '@smartplatform/model-store';
import { RouteStore } from '@smartplatform/route-store';
import { LocalStore } from '@smartplatform/local-store';
import UIStore from './uiStore';
import { localStoreConfig } from '@app/config/local-store/localStoreConfig';
import { observable } from 'mobx';
import { SocketStore } from './socketStore';
import { parseConfig, mergeConfigs } from 'client/tools';
import i18next from 'i18next';
import { THEMES } from '@app/config/theme';

/**
 * Глобальный контейнер сторов
 */
export class AppStore {
	local; // Локальное хранилище
	model; // Хранилище моделей
	route; // Хранилище маршрута браузера
	api; // API обмена с сервером
	admin; // Хранилище @admin
	ui; // ui
	socket; // вебсокет для общения с сервером

	serverConfig;
	dbServerConfig;
	@observable isInitialized = false;
	@observable appKey = 0;
	@observable menuKey = 0;
	@observable onlineUsersMap = new Map();
	currency = null;
	availableModules = [];

	subscribers = {};

	constructor() {
		this.history = createBrowserHistory();
		this.history.listen(this.onHistoryChange);
		this.ui = new UIStore(this);
		this.local = new LocalStore();
		this.socketStore = new SocketStore(this);

		this.local.extend(localStoreConfig);
		this.local.save();

		this.rest = new Rest();
		this.model = new ModelStore({
			transport: this.rest,
			cache: false,
			autoConnect: false,
			onLogin: this.onLogin,
		});

		this.route = new RouteStore({
			history: this.history,
			decode: qs.parse,
			encode: qs.stringify,
		});
	}

	/**
	 * Вызывается при успешной авторизации и переподключает вебсокет
	 * @param user
	 */
	onLogin = async (user) => {
		// переподключаем вебсокет
		this.serverConfig = this.model.config || {};
		await this.fetchDbServerConfig();
		this.socketStore.initSubscribe();
		this.socket.disconnect().connect();
		this.currency = (await this.model.Currency.find({ where: { isDefault: true }, limit: 1 }))[0];
	};

	get isAdmin() {
		return this.model.roles.map((i) => i.name).includes('admin');
	}

	get isManagerOrAdmin() {
		return this.model.roles.map((i) => i.name).some((role) => role === 'admin' || role === 'manager');
	}

	get maxSelectItems() {
		return window.innerHeight > 1000 ? 15 : 10;
	}

	get selectedThemePreset() {
		return this.local.isDarkMode ? THEMES.DARK : THEMES.DEFAULT;
	}

	checkModuleAccess = (moduleCode) => this.availableModules.includes(moduleCode);

	/**
	 * Инициализация стора
	 * @returns {Promise<void>}
	 */
	init = async () => {
		const language = navigator.language || navigator.userLanguage;
		const systemLanguage = language && language.toLocaleLowerCase().split('-')[0];

		i18next.changeLanguage(this.local.language || systemLanguage || 'en');
		this.local.language = this.local.language || systemLanguage || 'en';

		await this.model.connect();
		this.serverConfig = this.model.config || {};
		await this.fetchDbServerConfig();
		this.currency = (await this.model.Currency.find({ where: { isDefault: true }, limit: 1 }))[0];

		this.socketStore.initSubscribe();
		// переподключаем вебсокеты так как возможно пользователь уже авторизовался
		this.socket.disconnect().connect();

		document.body.className = this.local.isDarkMode ? 'dark-theme' : 'default-theme';
		this.isInitialized = true;
	};

	subscribe = (event, callback) => {
		if (!this.subscribers[event]) this.subscribers[event] = [];
		this.subscribers[event].push(callback);
	};

	unsubscribe = (event, callback) => {
		if (this.subscribers[event]) {
			const index = this.subscribers[event].findIndex((cb) => cb === callback);
			if (index !== -1) this.subscribers[event].splice(index, 1);
			if (this.subscribers[event].length === 0) delete this.subscribers[event];
		}
	};

	onHistoryChange = (e) => {
		if (this.subscribers.history) this.subscribers.history.forEach((cb) => cb(e));
	};

	fetchDbServerConfig = async () => {
		const configRecords = await this.model.Config.find();
		this.dbServerConfig = parseConfig(configRecords);
		this.mergedConfig = mergeConfigs({ serverConfig: this.serverConfig, dbConfig: this.dbServerConfig });
	};

	reloadApp = () => this.appKey++;
	reloadMenu = () => this.menuKey++;
}

window.APP_STORE = new AppStore();

export default window.APP_STORE;

