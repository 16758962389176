import React from 'react';
import {
	AddCircleButton,
	SaveIconButton,
	CancelIconButton,
	EditIconButton,
	DeleteIconButton,
	triggerDeleteNotification,
	FileImportIconButton,
	triggerInfoNotification,
	triggerSaveNotification,
} from 'components';
import './templates.scss';
import appStore from 'client/store';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import t from 'i18n';
import { AutoAnimate, Loader } from '@smartplatform/ui';

@observer
export class TemplatesToolPanel extends React.Component {
	@observable editRecord = null;
	@observable templates = [];
	@observable openIndex = null;
	@observable isInitialized = false;
	constructor(props) {
		super(props);
		this.gridRef = props.gridRef;
		this.init();
		this.ref = React.createRef();
	}

	init = async () => {
		this.templates = await appStore.model.AgGridTemplate.find({ where: { templateCode: this.props.code } });
		this.isInitialized = true;
	};

	onAddRecord = () => {
		const newRecord = new appStore.model.AgGridTemplate({ tableCode: this.props.code });
		this.templates.push(newRecord);
		this.editRecord = newRecord;
	};

	onCancelEdit = (template) => {
		if (!this.editRecord.id) {
			this.templates.pop();
		}
		if (template) {
			template.name = this.prevName;
		}

		this.editRecord = null;
		this.prevName = null;
	};

	onEditRecord = (template, e) => {
		e.stopPropagation();
		this.prevName = template.name;
		this.editRecord = template;
	};

	onSaveRecord = async (template, e) => {
		e.stopPropagation();
		const isNew = !template.id;
		if (isNew) {
			const state = this.gridRef.current.api.getColumnState();
			const isPivotMode = this.gridRef.current.api.isPivotMode();
			template.template = { state, isPivotMode };
		} else {
		}

		await template.save();
		const message = isNew ? 'agGrid.templateCreated' : 'agGrid.templateSaved';
		triggerSaveNotification(t(message, { template: template.name, oldName: this.prevName }));
		this.editRecord = null;
		this.prevName = null;
	};

	onNameChange = (template, e) => {
		template.name = e.target.value;
	};

	onDeleteRecord = async (template, index, e) => {
		const name = template.name;
		e.stopPropagation();
		await template.delete();
		this.templates.splice(index, 1);
		triggerDeleteNotification(t('agGrid.templateDeleted', { template: name }));
		this.onCancelEdit();
	};

	onLoadTemplate = (template) => {
		const { isPivotMode, state } = template.template;
		this.gridRef.current.api.applyColumnState({
			state,
			applyOrder: true,
		});
		this.gridRef.current.api.setGridOption('pivotMode', isPivotMode);
		// triggerInfoNotification(t('agGrid.templateLoaded', { template: template.name }));
	};

	onRewriteTemplate = async (template, e) => {
		e.stopPropagation();
		const state = this.gridRef.current.api.getColumnState();
		const isPivotMode = this.gridRef.current.api.isPivotMode();
		template.template = { state, isPivotMode };
		await template.save();
		triggerSaveNotification(t('agGrid.templateRewrited', { template: template.name }));
	};

	render() {
		const {
			onAddRecord,
			onSaveRecord,
			onNameChange,
			onEditRecord,
			templates,
			onCancelEdit,
			onLoadTemplate,
			editRecord,
			onRewriteTemplate,
			onDeleteRecord,
			isInitialized,
		} = this;
		if (!isInitialized) {
			return (
				<div className='templates-tool-panel'>
					<Loader />
				</div>
			);
		}
		return (
			<div className='templates-tool-panel'>
				<AutoAnimate className='templates-list'>
					{templates.map((template, index) => {
						if (template === this.editRecord) {
							return (
								<div className='edit-template' key='new'>
									<input
										value={template.name}
										className='ag-input-field-input ag-text-field-input'
										onChange={(value) => onNameChange(template, value)}
										placeholder={t('agGrid.namePlaceholder')}
									/>

									<div className='actions'>
										<SaveIconButton
											disabled={!template.name || template.name === this.prevName}
											onClick={(e) => onSaveRecord(template, e)}
											noBorder
											title={t('save')}
										/>
										<CancelIconButton
											onClick={() => onCancelEdit(template)}
											noBorder
											endText={false}
											title={t('cancel')}
										/>
										{template.id && (
											<DeleteIconButton
												noBorder
												onConfirm={(e) => onDeleteRecord(template, index, e)}
												title={t('delete')}
											/>
										)}
									</div>
								</div>
							);
						}
						return (
							<div
								className='template-item'
								onClick={() => onLoadTemplate(template)}
								title={t('agGrid.loadTemplate')}
								key={template.id}
							>
								<div className='name'>{template.name}</div>
								{!editRecord && (
									<div className='actions'>
										<FileImportIconButton
											noBorder
											onClick={(e) => onRewriteTemplate(template, e)}
											title={t('agGrid.rewriteTemplate')}
										/>

										<EditIconButton noBorder onClick={(e) => onEditRecord(template, e)} title={t('edit')} />
									</div>
								)}
							</div>
						);
					})}
				</AutoAnimate>
				<AddCircleButton onClick={onAddRecord} disabled={!!editRecord} title={t('add')} />
			</div>
		);
	}
}

