import React from 'react';
import classNames from 'classnames';

export default class SocialBtn extends React.Component {
	render() {
		const { onClick, providerName, icon: Icon } = this.props;
		return (
			<div className={classNames('wrapper-social-network-icon', providerName)} onClick={onClick}>
				<Icon />
			</div>
		);
	}
}
