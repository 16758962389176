import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Editor, ApproveIconButton, CancelIconButton, ConfirmPopup } from 'components';
import t from 'i18n';
import { Input } from '@smartplatform/ui';
import './postComment.scss';
import classNames from 'classnames';
import store from 'client/store';

@observer
export class PostComment extends React.Component {
	@observable isEditMode = false;
	@observable value = '';
	@observable isSubmitting = false;
	@observable error = null;
	@observable showChangedPopup = false;
	ref = React.createRef();

	static propTypes = {
		placeholder: PropTypes.string,
		onSubmit: PropTypes.func,
		relation: PropTypes.string,
		record: PropTypes.object,
		className: PropTypes.string,
	};
	static defaultProps = {
		relation: 'comments',
	};

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside = (event) => {
		// клик вне коммента
		const isMentionBtn = event.target.hasAttribute('data-mention-btn');
		if (this.isEditMode && this.ref && !this.ref.current?.contains(event.target) && !isMentionBtn) {
			if (this.value) {
				this.showChangedPopup = true;
			} else {
				this.onCancel();
			}
		}
	};

	onEditorInit = (editor) => {
		this.editor = editor;
	};

	onChange = (value) => {
		this.value = value;
	};

	onSave = async () => {
		this.error = null;
		if (this.value.trim().length === 0) {
			this.error = t('comment.empty');
			return;
		}
		try {
			this.isSubmitting = true;

			const images = await this.uploadImages();

			const comment = new store.model.Comment();
			comment.text = this.editor.getContent();
			await comment.save();

			for (let imgData of images) {
				const { element, uploadUri } = imgData;
				const filename = uploadUri.split('/').slice(-1)[0];
				const id = filename ? parseInt(filename.replace(/-filename/, '')) : null;
				if (id) await comment.attachments.add(id);
			}

			await this.props.record[this.props.relation].add(comment.id);
			this.props.onSubmit && this.props.onSubmit(comment);
			this.value = '';
			this.isSubmitting = false;
			this.toggleIsEditMode(false);
		} catch (e) {
			this.error = e.message;
		}
	};

	uploadImages = () =>
		new Promise((resolve, reject) => {
			this.editor.uploadImages((success) => {
				if (success) {
					resolve(success);
				} else {
					reject();
				}
			});
		});

	toggleIsEditMode = (value) => {
		if (value) {
			document.addEventListener('mousedown', this.handleClickOutside);
		} else {
			document.removeEventListener('mousedown', this.handleClickOutside);
		}
		this.isEditMode = value;
	};

	onCancel = () => {
		if (!this.showChangedPopup && this.value) {
			this.showChangedPopup = true;
			return;
		}

		this.value = '';
		this.toggleIsEditMode(false);
		this.showChangedPopup = false;
	};

	onOpenConfirmPopup = () => (this.showChangedPopup = true);
	onCloseConfirmPopup = () => (this.showChangedPopup = false);

	render() {
		let { placeholder = t('postComment.placeholder'), className, mentions } = this.props;
		const { toggleIsEditMode, onSave, onCancel, onCloseConfirmPopup } = this;
		className = classNames('post-comment-v2', className);

		return (
			<div className={className}>
				{this.isEditMode ? (
					<div ref={this.ref}>
						<Editor
							autoFocus
							value={this.value}
							onChange={this.onChange}
							mediaModel={store.model.Attachment}
							autoResize
							minHeight={180}
							onInit={this.onEditorInit}
							mentions={mentions}
							menubar={false}
							toolbar='bold italic underline alignleft aligncenter alignjustify alignright numlist bullist image media'
							disabled={this.isSubmitting}
						/>
						<div className='actions'>
							<ApproveIconButton onClick={onSave} disabled={!this.value} />
							<CancelIconButton onClick={onCancel} />
						</div>
					</div>
				) : (
					<Input className='input' placeholder={placeholder} onClick={() => toggleIsEditMode(true)} />
				)}
				{this.error && <div className='error'>{this.error}</div>}
				{this.showChangedPopup && (
					<ConfirmPopup
						onClose={onCloseConfirmPopup}
						onConfirm={onCancel}
						title={t('comment.confirmTitle')}
						description={t('comment.confirmDescription')}
						buttonText={t('confirm')}
					/>
				)}
			</div>
		);
	}
}

