export const TASK_INCLUDE = {
	include: [
		{ relation: 'checklists', scope: { include: { relation: 'positions', scope: { order: 'priority asc, id asc' } } } },
		{
			relation: 'project',
			scope: {
				fields: ['id', 'name', 'isLimitedTaskProperties'],
				include: [
					{
						relation: 'members',
						scope: {
							where: { userId: { neq: null } },
							fields: ['id', 'userId'],
							include: [
								'role',
								{
									relation: 'user',
									scope: {
										fields: ['avatar', 'id', 'lastName', 'firstName'],
									},
								},
							],
						},
					},
					{ relation: 'lists', scope: { fields: ['id', 'name', 'color'] } },
					'projectList',
				],
			},
		},
		{ relation: 'attachments', scope: { include: ['owner'] } },
		{ relation: 'boardList', scope: { fields: ['id', 'name', 'color'] } },
		{ relation: 'owner', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
		{ relation: 'user', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
		{ relation: 'labels', scope: { fields: ['id', 'name', 'color'] } },
	],
};

export const TASK_LOG_INCLUDE = {
	include: [
		{ relation: 'checklistPosition' },
		{ relation: 'owner', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
		{ relation: 'user', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
		{ relation: 'boardList', scope: { fields: ['id', 'name', 'color'] } },
		{ relation: 'project', scope: { include: ['projectList'], fields: ['id', 'name'] } },
		{ relation: 'label', scope: { fields: ['id', 'name', 'color'] } },
	],
	order: 'createdAt asc',
};

export const TASK_COMMENT_INCLUDE = {
	include: [
		{ relation: 'owner', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
		{ relation: 'user', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
		{ relation: 'attachments' },
	],
	order: 'createdAt asc',
};

export const TASK_PROJECT_MEMBERS = {
	relation: 'members',
	scope: {
		where: { userId: { neq: null } },
		fields: ['id', 'userId'],
		include: {
			relation: 'user',
			scope: {
				fields: ['avatar', 'id', 'lastName', 'firstName'],
			},
		},
	},
};

export const FIELD_RELATION_PROJECT_FILTER = {
	include: [
		{
			relation: 'projectList',
			scope: {
				fields: ['id', 'color'],
			},
		},
		{
			relation: 'lists',
			scope: {
				fields: ['color', 'name', 'id', 'default'],
			},
		},
		TASK_PROJECT_MEMBERS,
	],
};

