import t from 'i18n';
import { dateTimeToLocaleString, dateToLocaleString, numberToLocaleString } from 'client/tools/locale';
import store from 'client/store';

export const dataTypeDefinitions = {
	date: {
		baseDataType: 'date',
		extendsDataType: 'date',
		valueFormatter: (params) => {
			let result = params.value;
			// показывать дату в зависимости от локали юзера,
			// работает везде кроме группировки строк, для группировки срок нужно
			// использовать valueGetter в columnDefs
			if (result) {
				result = dateToLocaleString(params.value);
			}
			return result;
		},
	},
	dateTime: {
		// тоже самое что и date только в форматирование даты включено время
		baseDataType: 'date',
		extendsDataType: 'date',
		valueFormatter: (params) => {
			let result = params.value;

			if (result) {
				result = dateTimeToLocaleString(params.value);
			}
			return result;
		},
	},
	boolean: {
		baseDataType: 'boolean',
		extendsDataType: 'boolean',
		valueFormatter: (params) => {
			if (params.value === false) {
				return t('no');
			}
			if (params.value === true) {
				return t('yes');
			}
		},
	},
	number: {
		baseDataType: 'number',
		extendsDataType: 'number',
		// добавляет разделители разрядов, а также правильное направление
		// написания в зависимости от локали
		valueFormatter: (params) => {
			if (params.value) {
				numberToLocaleString(params.value);
			}
		},
	},
	currency: {
		baseDataType: 'number',
		extendsDataType: 'number',
		// добавляет валюту, пока рубли
		//! прокинуть названии валюты надо через currencyName, которое надо закинуть в запись строки
		valueFormatter: (params) => {
			const currency = params.data && params.data.currencyName || 'RUB';

			return numberToLocaleString(params.value, {
				style: 'currency',
				currency,
			});
		},
	},
};

