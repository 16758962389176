import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { renderUser, formatDate } from 'client/tools';
import ViewDocument from 'client/img/view-document.svg';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { Popover } from '@smartplatform/ui';
import { DeleteIconButton, IconTitle, TruncateText } from 'components';
import t from 'i18n';

import './customAttachment.scss';

@observer
export class CustomAttachment extends Component {
	@observable attachOwner = null;

	static defaultProps = {
		property: 'filename',
		noDelete: false,
		noOwner: false,
	};

	render() {
		const { attachment, property, isNew, deleteAttach, noDelete, noOwner } = this.props;

		const msg = (
			<>
				{t('deleteFile')}
				<br />
				<em>{attachment[property]}</em>?
			</>
		);

		return (
			<div className='attachment-with-data'>
				<div className='info'>
					<Popover content={attachment[property]} className='attachment-info-name'>
						<div className='icon-name'>
							<a className='file-name' target='_blank' href={attachment.downloadFile(property)}>
								<IconTitle faIcon={faFile}>
									<TruncateText>{attachment[property]}</TruncateText>
								</IconTitle>
							</a>
						</div>
					</Popover>
					{attachment.owner && !noOwner && <TruncateText className='user'>{renderUser(attachment.owner)}</TruncateText>}
					<span className='date'>{formatDate(attachment.updatedAt)}</span>
					<a className='view-document' target='_blank' href={attachment.downloadFile(property)}>
						<ViewDocument />
					</a>
					{!isNew && !noDelete && (
						<DeleteIconButton
							noBorder
							confirmMessage={msg}
							onConfirm={() => deleteAttach({ attachment, property })}
							size='small'
						/>
					)}
				</div>
			</div>
		);
	}
}
