import React from 'react';
import { Checkbox, Field, Loader, RecordSelect, Row } from '@smartplatform/ui';
import { inject, observer } from 'mobx-react';
import t from 'i18n';
import { AttachButton, Attachments, DateTimePicker, Editor, Form, LabelField, Section, UserSelectOld } from 'components';
import { renderPlaceholder, renderProject, renderStatus } from 'client/tools';
import store from 'client/store';
import { FIELD_RELATION_PROJECT_FILTER } from 'client/modules/management/tasks/single-page/constants';
import { TaskFormStore } from 'client/modules/messages/dialogs/single/category-erp/task/taskFormStore';
import { CustomAttachment } from 'components';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { MANAGEMENT_TASKS_PATH } from 'client/modules/management/constants';

@inject('store')
@observer
export class TaskForm extends React.Component {
	constructor(props) {
		super(props);
		this.store = new TaskFormStore(props.record, props.dialog);
	}

	render() {
		const { isLoading, enabledBoardLists, task, onChangeProject, loadingBoardLists, onDateChange, beforeSave } = this.store;
		const { onCancel } = this.props;
		if (isLoading) return <Loader size={20} />;

		const boardLists = enabledBoardLists.length ? enabledBoardLists.map((item) => item.id) : [];
		let selectedStatus = task.boardList ? renderStatus(task.boardList) : renderPlaceholder(t('select'));

		let members = [];
		if (task.project) {
			members = task.project.members().map((member) => member.userId);
		}

		const disabledSave = !task.project || !task.user;
		const disabledForm = task.id;

		return (
			<Section title={task.id ? <Link to={`${MANAGEMENT_TASKS_PATH}/${task.id}`}>{t('task.title')}</Link> : t('task.title')}>
				<Form
					record={task}
					noDelete
					stay
					onCancel={onCancel}
					noCancel={disabledForm}
					noSave={disabledForm}
					beforeSave={beforeSave}
					disableSave={disabledSave}
					checkRequiredFields={false}
				>
					<Row>
						<LabelField label={t('project.title')} isRequired>
							<RecordSelect
								value={task.project}
								model={store.model.Project}
								computed={renderProject}
								filter={FIELD_RELATION_PROJECT_FILTER}
								onChange={onChangeProject}
								showValue={!task.project && renderPlaceholder(t('project.select'))}
								isRequired
								disabled={disabledForm}
							/>
						</LabelField>
						<Field
							relation='boardList'
							computed={renderStatus}
							filter={{ where: { id: { inq: boardLists } } }}
							label={t('boardList.title')}
							isRequired
							disabled={!task.project || loadingBoardLists || !enabledBoardLists.length || disabledForm}
						>
							<RecordSelect value={task.boardList} model={store.model.BoardList} showValue={selectedStatus} isRequired />
						</Field>
					</Row>
					<Row className='bottom-align'>
						<Field
							relation='user'
							filter={{
								where: {
									and: [{ id: { inq: members } }],
								},
								order: 'isBlocked desc, lastName asc, firstName asc, username asc',
							}}
							label={t('executor')}
							disabled={!task.project || disabledForm}
							isRequired
						>
							<UserSelectOld />
						</Field>
						<Field property='private' className='private' disabled={disabledForm}>
							<Checkbox label={t('private')} />
						</Field>
					</Row>
					<Row>
						{['startDate', 'dueDate'].map((name) => (
							<Field property={name} label={t('task.' + name)} key={name} disabled={disabledForm}>
								<DateTimePicker
									value={task[name]}
									maxDate={name === 'startDate' && task.dueDate ? new Date(task.dueDate) : undefined}
									minDate={name === 'dueDate' && task.startDate ? new Date(task.startDate) : undefined}
									onClose={onDateChange(name, true)}
									onBlur={onDateChange(name, true)}
									onChange={onDateChange(name)}
									maxTime={store.ui.workEndTime}
									minTime={store.ui.workStartTime}
									{...(name === 'startDate' ? { setMinTimeOnSelect: true } : { setMaxTimeOnSelect: true })}
									showClearButton={!disabledForm}
								/>
							</Field>
						))}
					</Row>
					<Field property='description' label={t('task.description')}>
						{disabledForm ? (
							<div className='description-task' dangerouslySetInnerHTML={{ __html: task.description }} />
						) : (
							<Editor mediaModel={store.model.Attachment} minHeight={150} maxHeight={400} />
						)}
					</Field>
					<div className={classNames('attachment-files', { disabled: disabledForm })}>
						<Attachments
							RenderCustomAttachment={CustomAttachment}
							renderCustomAttachmentButton={<AttachButton />}
							record={task}
							canUpload={true}
							softDelete={true}
							archiveAttachments={true}
							withFormContext={true}
						/>
					</div>
				</Form>
			</Section>
		);
	}
}
