import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { debounce } from 'lodash';

import { Button, Input, Checkbox, NumberInput, RecordSelect } from '@smartplatform/ui';
import store from 'client/store';

@observer
export default class NomenclatureFeatureValue extends React.Component {
	@observable value = null;
	@observable possibleValues = [];

	constructor(props) {
		super(props);
		this.saveValue = debounce(this.saveValue, 500, { leading: false, trailing: true });
		this.init();
	}

	init = async () => {
		const { nomenclature, feature } = this.props;
		if (nomenclature.id) {
			const values = await store.model.ProductNomenclatureFeatureValue.find({
				where: {
					nomenclatureId: nomenclature.id,
					featureId: feature.id,
				},
				include: ['featureValue'],
			});
			this.value =
				values.length > 0
					? values[0]
					: new store.model.ProductNomenclatureFeatureValue({ nomenclatureId: nomenclature.id, featureId: feature.id });
		}

		if (feature.type === 'list') {
			this.possibleValues = await store.model.FeatureValue.find({
				where: { featureId: feature.id },
				order: 'order asc',
			});
		}
	};

	setBooleanValue = async (value) => {
		await this.checkValue();
		this.value.booleanValue = value;
		await this.saveValue();
	};

	setListValue = async (value) => {
		await this.checkValue();
		this.value.featureValueId = value.id;
		await this.saveValue();
	};

	checkValue = async () => {
		const { nomenclature, feature } = this.props;
		if (!this.value) {
			const newValue = new store.model.ProductNomenclatureFeatureValue();
			newValue.featureId = feature.id;
			newValue.nomenclatureId = nomenclature.id;
			this.props.nomenclature.id ? await newValue.save() : this.saveValue();
			this.value = newValue;
		}
	};

	saveValue = async () => {
		this.props.onChange && this.props.onChange(this.value);
	};

	onListChange = async (value) => {
		await this.checkValue();
		this.value.featureValue = value;
		this.value.featureValueId = value?.id;
		await this.saveValue();
	};

	setStringValue = async (value) => {
		await this.checkValue();
		this.value.stringValue = value;
		await this.saveValue();
	};

	setNumberValue = async (value) => {
		await this.checkValue();
		this.value.numberValue = value;
		await this.saveValue();
	};

	render() {
		const { nomenclature, feature } = this.props;

		let content = '-';
		if (feature.type === 'string') {
			content = <Input value={this.value?.stringValue || ''} onChange={this.setStringValue} />;
		} else if (feature.type === 'number') {
			content = <NumberInput value={this.value?.numberValue} onChange={this.setNumberValue} />;
		} else if (feature.type === 'boolean') {
			content = <Checkbox value={this.value?.booleanValue || false} onChange={this.setBooleanValue} />;
		} else if (feature.type === 'list') {
			content = (
				<RecordSelect
					model={store.model.FeatureValue}
					property='name'
					filter={{
						where: { featureId: feature.id },
						order: 'order asc',
					}}
					value={this.value?.featureValue}
					onChange={this.onListChange}
					showValue={this.value?.featureValue?.name}
				/>
			);
		}

		return <div className='feature-value'>{content}</div>;
	}
}
