import React from 'react';
import { Switch, Route } from 'react-router-dom';
import {
	CATALOG_PATH,
	ORDERS_PATH,
	ORDERS_REPORT_PATH,
	PRICE_CATEGORIES_PATH,
	SETTINGS_PATH,
	SALES_REPORTS_PATH,
	SALES_DASHBOARD_PATH,
} from './constants';
import loadable from '@loadable/component';
import CatalogRoute from 'client/modules/sales/catalog';
import t from 'i18n';
import { withTitleAndLink } from 'client/tools/hoc/withTitleAndLink';
const PriceLists = loadable(() => import('./pricelists'));
const PriceList = loadable(() => import('./pricelist'));
const Settings = loadable(() => import('./settings'));
const Orders = loadable(() => import('./orders'));
const Reports = loadable(() => import('./reports'));
const Dashboard = loadable(() => import('./dashboard'));

/**
 * Главный компонент с роутами и компонентами модуля.
 * Также может содержать, например, некую асинхронную логику инициализации.
 */

export default () => (
	<Switch>
		<Route path={SALES_DASHBOARD_PATH} component={withTitleAndLink(Dashboard, { title: t('analytics.title') })} />
		<Route path={ORDERS_PATH} component={Orders} />
		<Route path={ORDERS_REPORT_PATH} component={Orders} />
		<Route path={PRICE_CATEGORIES_PATH} component={PriceList} />
		{/* <Route path={PRICE_LISTS_PATH} component={PriceLists} /> */}
		<Route path={CATALOG_PATH} component={CatalogRoute} />
		<Route path={SALES_REPORTS_PATH} component={withTitleAndLink(Reports, { title: t('reports.plural') })} />
		<Route path={SETTINGS_PATH} component={withTitleAndLink(Settings, { title: t('settings') })} />
	</Switch>
);
