import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { ErrorMessage } from 'components/auth/components';
import t from 'i18n';
import store from 'client/store';
import { ADMIN_USERS_PATH } from 'client/modules/admin/constants';
import { getAccessTokenFromCookie } from 'client/tools';
import { Loader } from '@smartplatform/ui';

@observer
export class TwoFactorConfirm extends React.Component {
	@observable error = null;
	constructor(props) {
		super(props);
		this.token = props.match.params?.token;
		this.type = props.match.params?.type;
		this.init();
	}

	init = async () => {
		try {
			const result = await store.model.User.check2FAToken({ type: this.type, token: this.token });
			if (result && result.redirect && store.model.isAuthorized)
				store.route.push({ path: `${ADMIN_USERS_PATH}/${store.model.user.id}/user-settings` });

			if (result && !result.redirect && !store.model.isAuthorized) {
				if (getAccessTokenFromCookie()) {
					await store.init();
					store.route.push({ path: '/', search: {}, params: {} });
				}
			}
		} catch (error) {
			this.error = error?.code ? t(error.code) : t('ERROR_SOC_NET');
			console.error('error', error);
		}
	};

	render() {
		if (this.error) return <ErrorMessage field={this.error} />;
		return <Loader size={48} />;
	}
}
