import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import './feedback-btn.scss';
import store from 'client/store';

export default class FeedbackBtn extends React.Component {
	constructor(props) {
		super(props);
	}

	showFeedbackPopup = () => {
		store.ui.showFeedbackPopup = true;
	};

	render() {
		return (
			<div className='wrapper-feedback-btn' onClick={this.showFeedbackPopup}>
				<FontAwesomeIcon icon={faComments} />
			</div>
		);
	}
}
