import React from 'react';
import { LabelField, DateTimePicker, DatePicker } from 'components';
import { Checkbox, Field, Input, NumberInput, RecordSelect } from '@smartplatform/ui';
import { observer } from 'mobx-react';
import { MODEL_RENDERS } from 'components/сustom-model-properties';
import store from 'client/store';
/**
 * Компонент который используется в карточке записи для рендера доп полей
 */
@observer
export class RecordCustomField extends React.Component {
	constructor(props) {
		super(props);
		this.property = props.field.code;
	}

	onChange = (value) => (this.props.record[this.property] = value);

	onCheckboxChange = async (value) => {
		this.onChange(value);
		await this.afterSelectValue();
	};

	onChangeDate = async (value) => {
		this.onChange(value);
		if (!value) {
			await this.afterSelectValue();
		}
	};

	onRelationChange = async (value) => {
		this.onChange(value);
		await this.afterSelectValue();
	};

	afterSelectValue = async () => {
		if (this.props.saveOnChange && !this.isNew) {
			await this.props.record.save();
		}
	};

	render() {
		const { field, record } = this.props;
		const { code, name, type } = field;
		const value = record[code];

		if (type === 'Boolean') {
			return (
				<LabelField property={code} label={name}>
					<Checkbox value={value} onChange={this.onCheckboxChange} />
				</LabelField>
			);
		}

		if (type === 'String') {
			return (
				<LabelField label={name}>
					<Input onBlur={this.afterSelectValue} value={value} onChange={this.onChange} />
				</LabelField>
			);
		}

		if (type === 'Number') {
			return (
				<LabelField label={name}>
					<NumberInput onBlur={this.afterSelectValue} value={value} onChange={this.onChange} />
				</LabelField>
			);
		}

		if (type === 'Date' || type === 'Datetime') {
			const isDateTime = type === 'Datetime';
			const Component = isDateTime ? DateTimePicker : DatePicker;

			return (
				<LabelField label={name}>
					<Component
						value={record[code]}
						onChange={this.onChangeDate}
						onClose={this.afterSelectValue}
						onSelect={this.afterSelectValue}
					/>
				</LabelField>
			);
		}

		const computed = MODEL_RENDERS[type];
		const relationModelName = record.MODEL.RELATIONS[code]?.model;

		return (
			<LabelField label={name}>
				<RecordSelect computed={computed} model={store.model[relationModelName]} value={value} onChange={this.onRelationChange} />
			</LabelField>
		);
	}
}

