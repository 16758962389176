import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';

import { RecordSelect } from '@smartplatform/ui';
import store from 'client/store';
import './style.scss';
import t from 'i18n';
import './style.scss';
import { renderFaIcons } from 'client/tools';
import { IconTitle } from 'components';

@inject('productStore')
@observer
export class FeatureSelector extends React.Component {
	static propTypes = {
		featureType: PropTypes.oneOf(['product', 'variant']).isRequired,
	};

	static defaultProps = {
		featureType: 'variant',
	};

	@observable value = null;

	constructor(props) {
		super(props);
	}

	render() {
		const { featureType, productStore, text = t('add') } = this.props;
		if (productStore.isLoading) return null;

		const { nomenclaturesFeatures, toggleVariantFeature, productFeatures, toggleProductFeature } = productStore;

		const features = featureType === 'product' ? productFeatures : nomenclaturesFeatures;
		const toggle = featureType === 'product' ? toggleProductFeature : toggleVariantFeature;
		const filter = { where: { featureType }, order: 'id desc' };

		return (
			<RecordSelect
				model={store.model.Feature}
				value={features.map((f) => f.feature)}
				property='name'
				className='feature-selector'
				filter={filter}
				onChange={toggle}
				showValue={<IconTitle text={text} icon={renderFaIcons.plus} />}
				width={200}
			/>
		);
	}
}

