import React, { createRef } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { AutoAnimate } from '@smartplatform/ui';
import MenuItem from './MenuItem';
import store from 'client/store';
import { autorun, observable } from 'mobx';
import t from 'i18n';

@observer
export default class ModuleMenu extends React.Component {
	@observable items = [];
	ref = createRef(null);

	constructor(props) {
		super(props);
		this.init();
		autorun(() => {
			store.menuKey;
			this.setItems();
		});
	}

	init = () => {
		const { module } = this.props;
		this.setItems();
		if (!store.local.submenu) {
			store.local.submenu = {};
			store.local.save();
		}
		if (!store.local.submenu[module.code]) {
			store.local.submenu[module.code] = false;
			store.local.save();
		}
	};

	setItems = () => {
		const { module } = this.props;
		if (module.menu) {
			this.items = typeof module.menu === 'function' ? module.menu(store) : module.menu;
		}
	};

	changeVisibility = (isVisible) => {
		const { module } = this.props;
		if (typeof isVisible === 'boolean') {
			if (isVisible !== store.local.submenu[module.code]) {
				store.local.submenu = {
					...store.local.submenu,
					[module.code]: isVisible,
				};
				store.local.save();
			}
		} else {
			store.local.submenu = {
				...store.local.submenu,
				[module.code]: !store.local.submenu[module.code],
			};
			store.local.save();
		}
	};

	render() {
		const { module } = this.props;
		const { titleShort, code, settings, title } = module;
		const defaultTitle = t('module.' + code + '.title');
		const isActive = code === store.ui.activeSubMenu;
		let _title = typeof title === 'string' ? <strong>{title}</strong> : title;
		if (!_title) _title = <strong>{defaultTitle}</strong>;

		const className = classNames('submenu', {
			hidden: !store.local.submenu[code] && !isActive,
		});

		if (store.ui.menu) {
			return (
				<div className={className}>
					<div className='title' onClick={!isActive ? this.changeVisibility : () => {}}>
						{_title}
						<div className='actions'>
							{settings && (
								<Link className='action-btn' to={settings.path}>
									<FontAwesomeIcon icon={faGear} />
								</Link>
							)}
							<span className={'action-btn' + (isActive ? ' disabled' : '')}>
								<FontAwesomeIcon icon={faChevronUp} />
							</span>
						</div>
					</div>
					<AutoAnimate className='items'>
						{store.local.submenu[code] &&
							this.items.map((item, i) => {
								return (
									<MenuItem
										key={code + '-' + i + '-'}
										item={item}
										badgeFunc={item.badgeFunc}
										badgeEvent={item.badgeEvent}
										changeVisibility={this.changeVisibility}
										parentCode={code}
									/>
								);
							})}
					</AutoAnimate>
				</div>
			);
		} else {
			return (
				<div className={store.ui.menu ? 'submenu' : 'submenu submenu-minimised'}>
					<div className='title title-minimised'>
						{typeof titleShort === 'string' ? <strong>{titleShort}</strong> : titleShort}
					</div>
					<div className='items'>
						{this.items.map((item, i) => {
							if (item.submenu) {
								return (
									store.local.submenu[code] &&
									item.submenu.map((subItem, j) => (
										<MenuItem
											key={code + '-' + i + '-' + j}
											item={subItem}
											badgeFunc={subItem.badgeFunc}
											badgeEvent={subItem.badgeEvent}
											changeVisibility={this.changeVisibility}
											parentCode={code}
										/>
									))
								);
							} else {
								return (
									<MenuItem
										key={code + '-' + i}
										item={item}
										badgeFunc={item.badgeFunc}
										badgeEvent={item.badgeEvent}
										changeVisibility={this.changeVisibility}
										parentCode={code}
									/>
								);
							}
						})}
					</div>
				</div>
			);
		}
	}
}
