import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Form } from 'components';
import { Field, Loader } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import i18next from 'i18next';

const FIELD_BY_SYSTEM = {
	VK: ['login', 'secretKey'],
	TLG: ['login', 'secretKey'],
	EMAIL: ['login', 'secretKey', 'url', 'urlOut'],
	WA: ['login', 'secretKey', 'url'],
};

@observer
export class Edit extends React.Component {
	@observable record = null;
	constructor(props) {
		super(props);
		this.getRecord(parseInt(props.match.params.id));
	}

	getRecord = async (id) => {
		this.record = !id ? new store.model.DialogSource() : await store.model.DialogSource.findById(id);
	};

	back = () => {
		store.route.push({ path: this.props.path });
	};

	render() {
		if (!this.record) return <Loader size={20} />;

		return (
			<Form record={this.record} onCancel={this.back} onSave={this.back} noDelete={this.record?.isProtected} onDelete={this.back}>
				<Field label={t('name')} property='title' />
				<Field label={t('code')} property='code' />
				{this.record.code &&
					FIELD_BY_SYSTEM[this.record.code] &&
					FIELD_BY_SYSTEM[this.record.code].map((property) => {
						const label = i18next.exists(`dialogSource.label.${this.record.code}.${property}`)
							? t(`dialogSource.label.${this.record.code}.${property}`)
							: t(`dialogSource.${property}`);
						return <Field label={label} property={property} />;
					})}
			</Form>
		);
	}
}
