import React from 'react';
import './onlineBadge.scss';
import PropTypes from 'prop-types';

export default class OnlineBadge extends React.Component {
	static propTypes = {
		sizePercentage: PropTypes.number, // размер в процентах относительно родителя
		isOnline: PropTypes.bool,
	};

	static defaultProps = {
		sizePercentage: 30,
	};

	render() {
		const { children, isOnline, sizePercentage = 20 } = this.props;
		const wrapperStyle = {
			top: `${85 - sizePercentage / 2}%`,
			left: `${85 - sizePercentage / 2}%`,
			width: `${sizePercentage}%`,
			height: `${sizePercentage}%`,
		};

		return (
			<div className='online-badge'>
				{children}
				{isOnline !== undefined && (
					// белая обертка
					<span className='online-flag' style={wrapperStyle}>
						<span className={isOnline ? 'is-online' : 'is-offline'} />
					</span>
				)}
			</div>
		);
	}
}

