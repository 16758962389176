import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './switch.scss';

@observer
export class Switch extends React.Component {
	static propTypes = {
		value: PropTypes.bool.isRequired,
		onChange: PropTypes.func.isRequired,
		disabled: PropTypes.bool,
	};

	static defaultProps = {
		disabled: false,
	};
	constructor(props) {
		super(props);
	}

	onChange = () => {
		if (this.props.onChange) this.props.onChange(!this.props.value);
	};

	render() {
		return (
			<div
				className={classNames({
					'switch-toggle-wrapper': true,
					active: this.props.value,
					disabled: this.props.disabled,
				})}
				onClick={this.onChange}
			>
				<div className='circle'></div>
			</div>
		);
	}
}
