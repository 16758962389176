import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { UserAvatar } from './UserAvatar';
import './style.scss';
import store from 'client/store';
import { OnlineBadge } from 'components';

@observer
export default class AvatarGroup extends React.Component {
	static propTypes = {
		items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
		size: PropTypes.number,
		avatarsCount: PropTypes.number,
		gap: PropTypes.number,
		badgeKey: PropTypes.string,
		renderBadge: PropTypes.func,
		onItemClick: PropTypes.func,
	};

	static defaultProps = {
		size: 38,
		avatarsCount: null,
		gap: 5,
		badgeKey: null,
		renderBadge: undefined,
	};

	@observable popup = LOADING;

	constructor(props) {
		super(props);
	}

	render() {
		const { items, size, avatarsCount, style, badgeKey, renderBadge, onItemClick, selectedId } = this.props;
		const avatars = avatarsCount ? items.slice(0, avatarsCount) : items;
		const diff = items.length - avatars.length;
		return (
			<div className='avatars'>
				{avatars.map((item, index) => (
					<div className='avatar-wrapper' onClick={() => (onItemClick ? onItemClick(item) : {})} key={item.id || index}>
						<OnlineBadge isOnline={store.onlineUsersMap.has(item.user ? item.user.id : item.id)}>
							<UserAvatar
								selected={item.user ? selectedId === item.user?.id : selectedId === item.id}
								user={item.user || item}
								role={item.role}
								size={size}
								style={style}
								badge={renderBadge ? renderBadge(item) : badgeKey ? item[badgeKey] : undefined}
							/>
						</OnlineBadge>
					</div>
				))}
				{diff > 0 && <span className='more'>{`+${diff}`}</span>}
			</div>
		);
	}
}

