import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import isAfter from 'date-fns/isAfter';

import { Loader } from '@smartplatform/ui';
import { Comment, PostComment } from 'components';
import t from 'i18n';
import './style.scss';

const stages = {
	LOADING: 'loading',
	RELOADING: 'reloading',
	DONE: 'done',
	ERROR: 'error',
};

@observer
export class Comments extends React.Component {
	static propTypes = {
		record: PropTypes.object.isRequired,
		relation: PropTypes.string,
	};

	static defaultProps = {
		relation: 'comments',
	};

	@observable status = stages.LOADING;
	@observable error = null;
	@observable comments = [];

	constructor(props) {
		super(props);
		this.props.instance && this.props.instance({ reload: this.reload });
		this.load(true);
	}

	load = async (full = false) => {
		if (this.status !== stages.LOADING) this.status = stages.RELOADING;
		try {
			const comments = await this.props.record[this.props.relation].find({
				include: [
					{ relation: 'owner', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
					{ relation: 'user', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
					{ relation: 'attachments' },
				],
				where: { type: null },
				order: 'createdAt asc',
				skip: full ? 0 : this.comments.length,
			});

			this.comments = full ? comments : [...this.comments, ...comments];
			this.status = stages.DONE;
		} catch (e) {
			this.error = e.message;
			this.status = stages.ERROR;
		}
	};

	reload = async () => {
		this.load(true);
	};

	onSubmitComment = async () => {
		this.load();
		this.props.onChange && (await this.props.onChange(['updatedAt']));
	};

	onCommentDelete = async (comment) => {
		const attachments = await comment.attachments();
		for (let attachment of attachments) {
			await attachment.deleteFile('filename');
			await attachment.delete();
		}
		await comment.delete();
		this.reload();
	};

	render() {
		if (this.status === stages.LOADING) return <Loader />;
		if (this.status === stages.ERROR) return <div className='error'>{this.error}</div>;

		const { record, relation } = this.props;

		const comment = this.comments.map((comment) => {
			return <Comment key={'comment-' + comment.id} comment={comment} className='task-comment' onDelete={this.onCommentDelete} />;
		});

		return (
			<div className='comments'>
				{this.props.title && <div className='title'>{this.props.title}</div>}
				<div className='comments-list'>{comment}</div>
				<PostComment record={record} relation={relation} onSubmit={this.onSubmitComment} />
			</div>
		);
	}
}

