import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { IconButton, ConfirmPopup } from 'components';
import classNames from 'classnames';
import t from 'i18n';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
@observer
export class DeleteIconButton extends React.Component {
	@observable showPopup = false;
	openPopup = () => (this.showPopup = true);
	closePopup = () => (this.showPopup = false);

	handleClick = (e) => {
		const { onConfirm, onClick } = this.props;
		e.stopPropagation();
		onConfirm ? this.openPopup() : onClick();
	};

	render() {
		const {
			confirmMessage,
			onConfirm,
			portalClassName,
			popPosition,
			disabled,
			noBorder,
			onActivate,
			size,
			contentClassName,
			popClassName,
			className,
			onClick,
			...otherButtonProps
		} = this.props;

		const button = (
			<IconButton
				icon={<FontAwesomeIcon icon={faTrashCan} />}
				className={classNames('delete-btn', className)}
				disabled={disabled}
				noBorder={noBorder}
				size={size}
				onClick={this.handleClick}
				{...otherButtonProps}
			/>
		);

		if (!onConfirm && onClick) return button;
		return (
			<>
				{button}
				{this.showPopup && (
					<ConfirmPopup
						onClose={this.closePopup}
						title={t('delete')}
						description={t('deleteDescription')}
						buttonText={t('delete')}
						buttonVariant='danger'
						onConfirm={onConfirm}
					/>
				)}
			</>
		);
	}
}

