import React from 'react';
import { observer } from 'mobx-react';
import ModuleMenu from './ModuleMenu';
import { getAvailableModules } from 'client/tools';
import './menu.scss';
import t from 'i18n';
import { MAIN_DASHBOARD_PATH } from 'client/constants';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop } from '@fortawesome/free-solid-svg-icons';
import store from 'client/store';
import classNames from 'classnames';

@observer
export default class Menu extends React.Component {
	render() {
		const modulesMenu = [];
		for (const module of getAvailableModules()) {
			modulesMenu.push(<ModuleMenu key={module.code} module={module} />);
		}

		return (
			<div className='menu' ref={this.onMount}>
				{/*<Link to={MAIN_DASHBOARD_PATH} className={classNames('main-menu-link', { minimised: !store.ui.menu })}>
					{!store.ui.menu ? <FontAwesomeIcon icon={faDesktop} /> : <strong>{t('mainDashboard')}</strong>}
				</Link>*/}
				{modulesMenu}
			</div>
		);
	}
}
