import appStore from 'client/store';
import { TemplatesToolPanel } from './templates/Templates';
import { SettingsToolPanel } from './settings/Settings';
import t from 'i18n';

export const getSideBar = ({ gridRef, templateCode }) => ({
	toolPanels: [
		{
			id: 'columns',
			labelDefault: 'Columns',
			labelKey: 'columns',
			iconKey: 'columns',
			toolPanel: 'agColumnsToolPanel',
			toolPanelParams: {
				suppressPivots: true,
				suppressRowGroups: true,
			},
		},
		{
			id: 'filters',
			labelDefault: 'Filters',
			labelKey: 'filters',
			iconKey: 'filter',
			toolPanel: 'agFiltersToolPanel',
		},
		templateCode && {
			id: 'templates',
			labelDefault: t('agGrid.templates'),
			labelKey: 'templates',
			iconKey: 'menu',
			toolPanel: () => <TemplatesToolPanel code={templateCode} gridRef={gridRef} />,
		},
		{
			id: 'settings',
			labelDefault: t('agGrid.settings'),
			labelKey: 'settings',
			iconKey: 'settings',
			toolPanel: () => <SettingsToolPanel gridRef={gridRef} />,
		},
	].filter(Boolean),
	position: appStore.local.agGrid.leftSidebar ? 'left' : 'right',
});

