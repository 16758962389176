import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { DeleteIconButton, IconTitle, TruncateText } from 'components';
import t from 'i18n';
import { faFile } from '@fortawesome/free-solid-svg-icons';

@observer
export default class Attachment extends React.Component {
	static propTypes = {
		attachment: PropTypes.object,
		property: PropTypes.string,
		onDelete: PropTypes.func,
		canDelete: PropTypes.bool,
	};

	static defaultProps = {
		property: 'filename',
	};

	render() {
		const { attachment, property, isNew, deleteAttach, canDelete } = this.props;

		const msg = (
			<>
				{t('deleteFile')}
				<br />
				<em>{attachment[property]}</em>?
			</>
		);

		return (
			<div className='attachment'>
				<div className='info'>
					<a className='file-name' target='_blank' href={attachment.downloadFile(property)}>
						<IconTitle faIcon={faFile}>
							<TruncateText>{attachment[property]}</TruncateText>
						</IconTitle>
					</a>
					{!isNew && canDelete && (
						<DeleteIconButton
							noBorder
							confirmMessage={msg}
							onConfirm={() => deleteAttach({ attachment, property })}
							size='small'
						/>
					)}
				</div>
			</div>
		);
	}
}

