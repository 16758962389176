import React, { createRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { AutoAnimate, Button } from '@smartplatform/ui';
import { Link } from 'react-router-dom';
import { CATALOG_PATH } from 'client/modules/sales/constants';
import { DeleteIconButton } from 'components';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';

@inject('productListStore')
@observer
export default class CategoryTools extends React.Component {
	@observable showSetting = false;
	constructor(props) {
		super(props);
		this.refControls = createRef();
		this.store = props.productListStore;
	}

	static propTypes = {
		category: PropTypes.object,
	};

	componentDidMount() {
		document.addEventListener('click', this.handleOutsideClick);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleOutsideClick);
	}

	handleOutsideClick = (event) => {
		if (this.refControls && !this.refControls.current.contains(event.target) && this.showSetting) {
			this.showSetting = false;
		}
	};

	toggleSettings = (e) => {
		e.stopPropagation();
		this.showSetting = !this.showSetting;
	};

	delete = async () => {
		this.showSetting = false;
		await this.store.deleteCategory(this.props.category.id);
	};

	render() {
		const countProducts = this.props.category.products.length;

		return (
			<div className='wrapper-tools-category' onClick={this.toggleSettings} ref={this.refControls}>
				<FontAwesomeIcon icon={faEllipsisH} />
				<AutoAnimate className='wrapper-controls-list'>
					{!!this.showSetting && (
						<div className={`controls ${countProducts && 'disabled-delete'}`}>
							<Link to={`${CATALOG_PATH}/category/${this.props.category.id}`}>
								<Button>
									<FontAwesomeIcon icon={faEdit} />
								</Button>
							</Link>
							{!countProducts && <DeleteIconButton onConfirm={this.delete} popPosition='left' />}
						</div>
					)}
				</AutoAnimate>
			</div>
		);
	}
}
