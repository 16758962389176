import React from 'react';
import { observer, inject } from 'mobx-react';

import NomenclatureCard from './nomenclature/NomenclatureCard';

@inject('productStore')
@observer
export default class Variants extends React.Component {
	render() {
		const { productStore } = this.props;
		const { nomenclatures } = productStore;

		return nomenclatures.map((nomenclature, i) => <NomenclatureCard key={nomenclature.id || 'new'} nomenclature={nomenclature} number={++i}/>);
	}
}

