import React from 'react';
import store from 'client/store';
import YandexIcon from '../../../img/icons/yandex-icon.svg';
import GoogleIcon from '../../../img/icons/googleicon.svg';
import VKIcon from '../../../img/icons/vk-icon.svg';
import MailIcon from '../../../img/icons/mailru-icon.svg';
import SocialBtn from 'components/auth/social-network/SocialBtn';
import * as VKID from '@vkid/sdk';

import './style.scss';
import { generateSHA256Hash } from './tools/generateSHA256Hash';

export class SocialNetwork extends React.Component {
	constructor(props) {
		super(props);
		this.settings = store.mergedConfig?.authentication?.socialNetwork;
		if (this.settings) {
			this.enableVK = !!this.settings.vkApplicationId && !!this.settings.vkClientSecret;
			this.enableYandex = !!this.settings.yandexApplicationId && !!this.settings.yandexClientSecret;
			this.enableGoogle = !!this.settings.googleApplicationId && !!this.settings.googleClientSecret;
			this.enableMail = !!this.settings.mailApplicationId && !!this.settings.mailClientSecret;
		}
	}

	vkOnClick = async () => {
		const { vkApplicationId, vkClientSecret } = this.settings;
		const state = this.generateState(16);
		VKID.Config.init({
			app: vkApplicationId,
			redirectUrl: `${location.origin}/auth/vk`,
			mode: VKID.ConfigAuthMode.Redirect,
			scope: 'email phone',
			prompt: ['login'],
			state: state,
		});
		store.local.socialAuth.state = await generateSHA256Hash(`${state}:${vkClientSecret}`);
		store.local.save();
		VKID.Auth.login();
	};

	yandexOnClick = async () => {
		const { yandexApplicationId, yandexClientSecret } = this.settings;
		const state = this.generateState(16);
		store.local.socialAuth.state = await generateSHA256Hash(`${state}:${yandexClientSecret}`);
		store.local.save();
		const query = `?redirect_uri=${location.origin}/auth/yandex&response_type=code&client_id=${yandexApplicationId}&state=${state}`;
		location.href = `https://oauth.yandex.ru/authorize${query}`;
	};

	googleOnClick = async () => {
		const { googleApplicationId, googleClientSecret } = this.settings;
		const state = this.generateState(16);
		store.local.socialAuth.state = await generateSHA256Hash(`${state}:${googleClientSecret}`);
		store.local.save();
		const scope = 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email';
		const query =
			`?redirect_uri=${location.origin}/auth/google` +
			`&prompt=consent` +
			`&response_type=code` +
			`&client_id=${googleApplicationId}` +
			`&scope=${scope}` +
			`&state=${state}`;
		location.href = `https://accounts.google.com/o/oauth2/v2/auth${query}`;
	};

	mailOnClick = async () => {
		const { mailApplicationId, mailClientSecret } = this.settings;
		const state = this.generateState(16);
		store.local.socialAuth.state = await generateSHA256Hash(`${state}:${mailClientSecret}`);
		store.local.save();
		const scope = 'userinfo email phone';
		const query =
			`?redirect_uri=${location.origin}/auth/mailru` +
			`&response_type=code` +
			`&client_id=${mailApplicationId}` +
			`&scope=${scope}` +
			`&state=${state}` +
			`&prompt_force=1`;
		location.href = `https://oauth.mail.ru/login${query}`;
	};

	generateState = (length) => {
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		let state = '';
		for (let i = 0; i < length; i++) {
			state += characters.charAt(Math.floor(Math.random() * characters.length));
		}
		return state;
	};

	render() {
		if (!this.settings) return null;
		const { enableVK, enableYandex, enableGoogle, enableMail } = this;

		return (
			<div className='wrapper-social-network'>
				{enableVK && <SocialBtn onClick={this.vkOnClick} icon={VKIcon} providerName='vk' />}
				{enableYandex && <SocialBtn onClick={this.yandexOnClick} icon={YandexIcon} providerName='yandex' />}
				{enableGoogle && <SocialBtn onClick={this.googleOnClick} icon={GoogleIcon} providerName='google' />}
				{enableMail && <SocialBtn onClick={this.mailOnClick} icon={MailIcon} providerName='mail' />}
			</div>
		);
	}
}
