import React from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Popover, Input } from '@smartplatform/ui';
import t from 'i18n';

@observer
export class ToolbarSearch extends React.Component {
	static propTypes = {
		content: PropTypes.any,
		onSearch: PropTypes.func.isRequired,
		value: PropTypes.string,
		placeholder: PropTypes.string,
		className: PropTypes.string,
	};

	render() {
		const { content, className, placeholder = t('searchName'), value, onSearch, endIcon, ...restProps } = this.props;

		const _className = classNames('toolbar-search', className);

		const input = (
			<>
				<input value={value} onChange={onSearch} placeholder={placeholder} {...restProps} />
				<span className='icon'>{endIcon || <FontAwesomeIcon icon={faSearch} />}</span>
			</>
		);

		if (content) {
			return (
				<Popover content={content} contentClassName='toolbar-filter-popup' position='bottom' trigger='click' className={_className}>
					{input}
				</Popover>
			);
		}

		return <div className={_className}>{input}</div>;
	}
}

