import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import classNames from 'classnames';
import './badgeTitle.scss';

const TYPES = {
	beta: {
		badge: 'beta',
		badgeTitle: t('betaPopover'),
	},
	old: {
		badge: 'old',
		badgeTitle: t('oldContentPopover'),
	},
	new: { badge: 'new' },
};

export class BadgeTitle extends React.Component {
	constructor(props) {
		super(props);
	}

	static propTypes = {
		type: PropTypes.string,
		badgeTitle: PropTypes.string,
		badge: PropTypes.any,
	};

	render() {
		const className = classNames('badge', this.props.type);
		return (
			<div className='badge-title'>
				<span className='title'>
					{this.props.content}
					<span className={className} title={this.props.badgeTitle || TYPES[this.props.type]?.badgeTitle}>
						{this.props.badge || TYPES[this.props.type]?.badge}
					</span>
				</span>
			</div>
		);
	}
}

