import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Description } from './description';
import { Features } from './features';
import { AmountRangeGroup } from './amount-ranges/AmountRangeGroup';

@observer
export class Sidebar extends Component {
	render() {
		return (
			<div className='product-additional-information'>
				<Description />
				{this.props.id && (
					<>
						<Features />
						<AmountRangeGroup />
					</>
				)}
			</div>
		);
	}
}

