import { merge } from 'lodash';

export const mergeConfigs = ({ dbConfig, serverConfig }) => {
	const { modules: dbModules, ...restDbConfig } = dbConfig;
	// мердж без модулей из дб
	const mergedConfig = merge({ modules: [] }, restDbConfig, serverConfig);
	const mergedModules = mergedConfig.modules;

	// если модули есть, то меджим и их
	if (dbModules) {
		for (const key of Object.keys(dbModules)) {
			const index = mergedModules.findIndex((el) => key === (el?.name || el));
			//добавляем
			if (index === -1) mergedModules.push({ name: key, ...dbModules[key] });
			//строковое заменяем
			else if (typeof mergedModules[index] === 'string') {
				mergedModules[index] = { name: key, ...dbModules[key] };
				// объект меджим
			} else {
				mergedModules[index] = merge({}, dbModules[key], mergedModules[index]);
			}
		}
	}
	return mergedConfig;
};

//test
// const dbtest = {
// 	option1: true,
// 	mergeOptions: {
// 		key2: 2,
// 	},
// 	modules: {
// 		// push
// 		manafacture: { showAllMatarials: true },
// 		// merge with object
// 		finanse: { showTotal: true },
// 		// merge with string
// 		management: { hideDashboard: true },
// 	},
// };
// const servertest = {
// 	option2: true,
// 	mergeOptions: {
// 		key: 1,
// 	},
// 	modules: [
// 		'sales',
// 		'management',
// 		{
// 			name: 'finanse',
// 			showTotal: true,
// 		},
// 	],
// };

// console.log(mergeConfigs({ dbConfig: dbtest, serverConfig: servertest }));

