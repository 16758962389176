import store from 'client/store';
import t from 'i18n';
import { autorun, observable } from 'mobx';
import { COLUMNS_EXPORT, PRODUCT_INCLUDE } from './constants';
import format from 'date-fns/format';

export class productListStore {
	@observable products = [];
	// @observable isLoading = true;
	@observable searchValue = '';
	@observable suggestions = [];
	@observable paramsCategoryId = null;
	@observable isInputFocus = false;
	@observable epmtyString = '';
	@observable categories = [];
	@observable breadcrumpsItems = [];
	@observable isInitialized = false;
	@observable page = 1;
	@observable perPage = 20;

	categoriesMap = null;
	path;

	constructor({ path }) {
		this.path = path;
	}

	init = async () => {
		await this.fetchAllCategories();
		autorun(() => {
			this.paramsCategoryId;
			this.setCategories();
			this.setBreadcrumpsItems();
			this.setProducts();
		});
		this.isInitialized = true;
	};

	setProducts = () => {
		const categodyId = this.paramsCategoryId;
		const category = this.categoriesMap.get(categodyId);
		this.products = category ? category.products : [];
	};

	setCategories = () => {
		const parentId = this.paramsCategoryId;
		this.categories = this.categoriesArr.filter((cat) => cat.parentId === parentId);
		this.page = 1;
	};

	deleteCategory = async (id) => {
		await store.model.ProductCategory.deleteById(id);
		await this.fetchAllCategories();
		this.setCategories();
	};

	fetchAllCategories = async () => {
		const allCategories = await store.model.ViewProductCategory.find({
			include: [{ relation: 'products', scope: { include: PRODUCT_INCLUDE } }],
			fields: ['name', 'id', 'parentId', 'priority'],
			order: 'priority desc',
		});

		const productsArr = [];
		this.categoriesMap = new Map();
		allCategories.forEach((category) => {
			const obj = {
				id: category.id,
				parentId: category.parentId,
				name: category.name,
				products: category.products(),
				MODEL: category.MODEL,
			};
			productsArr.push(...category.products());
			this.categoriesMap.set(category.id, obj);
		});
		this.categoriesArr = Array.from(this.categoriesMap, ([key, value]) => ({ ...value }));
		this.productsArr = productsArr;
	};

	doSearch = async () => {
		this.epmtyString = '';
		const filteredProducts = this.productsArr.filter((x) => x?.name?.toLowerCase().includes(this.searchValue?.toLowerCase()));
		const filteredCategories = this.categoriesArr.filter((x) => x?.name?.toLowerCase().includes(this.searchValue?.toLowerCase()));
		this.suggestions = [...filteredCategories, ...filteredProducts];
		if (!this.suggestions.length) {
			this.epmtyString = 'ничего';
		}
	};

	onSearch = (e) => {
		this.suggestions = [];
		this.searchValue = e.target.value;
		const searchValue = this.searchValue.trim();

		if (searchValue.length) {
			this.epmtyString = '';
			this.doSearch();
		}
	};

	onSuggestionSelect = (value, e) => {
		if (value.MODEL.name === 'ViewProductCategory') {
			store.route.push({ path: this.path, params: { categoryParentId: value.id } });
		} else if (value.MODEL.name === 'ViewProduct') {
			store.route.push({ path: `${this.path}/product/${value.id}` });
		}

		this.searchValue = value.name;
	};

	onFocus = () => (this.isInputFocus = true);
	onBlur = () => (this.isInputFocus = false);
	onClearCategory = () => (this.searchValue = '');

	setBreadcrumpsItems = () => {
		let categoryId = this.paramsCategoryId;
		const rootCategory = { title: t('catalog.title'), path: this.path };
		const breadcrumbs = [];
		while (categoryId) {
			const category = this.categoriesMap.get(categoryId);
			if (!category) break;
			breadcrumbs.push({ title: category.name, path: `${store.route.path}?categoryParentId=${category.id}` });
			categoryId = category.parentId;
		}
		if (breadcrumbs.length) {
			breadcrumbs[0].path = null;
		}

		this.breadcrumpsItems = [rootCategory, ...breadcrumbs.reverse()];
	};

	exportCatalog = async (categoryId) => {
		const filter = { where: { and: [{ productId: { neq: null } }] } };
		if (!!categoryId) filter.where.and.push({ categoryId: categoryId });
		const params = {
			filter: filter,
			cols: COLUMNS_EXPORT,
		};
		const query = `?params=${encodeURIComponent(JSON.stringify(params))}`;
		const path = `/api/ViewCatalogs/exportXLSX${query}`;

		const downloadLink = document.createElement('a');
		downloadLink.href = path;
		downloadLink.rel = 'noopener noreferrer';
		downloadLink.click();
		downloadLink.remove();
	};
}
