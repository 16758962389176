import React, { Component, createRef } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { AvatarEdit } from './AvatarEdit';
import { FormContext, Loader } from '@smartplatform/ui';
import defaultAvatarUrl from '/client/img/profile/userpic.png';
import store from 'client/store';
import t from 'i18n';

import './imageLoader.scss';

@observer
export class ImageLoader extends Component {
	@observable file = null;
	@observable isEditAvatarPopup = false;
	@observable uploadCount = 0;
	@observable isLoading = false;

	record;
	property;
	form;
	fileName;

	static contextType = FormContext;

	constructor(props, context) {
		super(props);
		this.form = props.__data?.form || context.form;
		this.record = props.__data?.record || props.record;
		this.property = props.__data?.property || props.property;
	}

	onPopUpClose = () => {
		this.isEditAvatarPopup = false;
	};

	onChange = (e) => {
		const file = e.target.files[0];
		const reader = new FileReader();

		const _this = this;
		reader.onload = function (e) {
			_this.file = e.target.result;
			_this.fileName = file.name;
		};
		reader.readAsDataURL(file);
	};

	onSaveAvatar = async (editorRef) => {
		if (editorRef) {
			this.isLoading = true;
			const canvas = editorRef.getImage().toDataURL();

			const res = await fetch(canvas);
			const blob = await res.blob();

			if (this.record.id) {
				await this.record.patchAttributes({ [this.property]: this.fileName });
				await this.record.uploadFile(this.property, new File([blob], this.fileName, { type: blob.type }));
			} else {
				this.record[this.property] = this.fileName;
				this.form.setFile({
					file: new File([blob], this.fileName, { type: blob.type }),
					property: this.property,
				});
			}

			this.isEditAvatarPopup = false;
			this.uploadCount++;
			this.isLoading = false;
		}
	};

	delete = async () => {
		await this.record.patchAttributes({ [this.property]: null });
		await this.record.deleteFile(this.property);
		this.isEditAvatarPopup = false;
	};

	render() {
		if (this.isLoading) return <Loader />;

		const { width, height, scale } = this.props;

		let src;
		if (!this.record.id && this.file) {
			src = this.file;
		} else if (this.record.id && this.record[this.property]) {
			src = this.record.downloadFile(this.property) + `?image=[{"resize":{"height":160}}]&v=${this.uploadCount}`;
		} else src = defaultAvatarUrl;

		const avatarProps = {
			width,
			height,
			borderRadius: 500,
			scale,
		};

		const avatarEditProps = {
			disabledSave: !this.file,
			disabledDelete: !this.record[this.property],
			onDelete: this.delete,
			onSave: this.onSaveAvatar,
			onClose: this.onPopUpClose,
			onChange: this.onChange,
			imageSrc: src,
			file: this.file,
			isNewRecord: !this.record.id,
			avatarEditorProps: avatarProps,
		};

		return (
			<div className='avatar-image-loader'>
				<div className='avatar-image-preview' onClick={() => (this.isEditAvatarPopup = true)}>
					<img src={src} alt={`${this.record[this.property]}`} loading='lazy' />
				</div>

				{this.isEditAvatarPopup && <AvatarEdit {...avatarEditProps} />}
			</div>
		);
	}
}

