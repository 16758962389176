import React, { useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import noImage from 'client/img/no-image.svg?url';
import './imageUploader.scss';
import { FormContext, Popconfirm } from '@smartplatform/ui';
import t from 'i18n';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DeleteIconButton } from 'components';

export const ImageUploader = ({ onChange, __data, record, property }) => {
	const _record = __data?.record || record;
	const _property = __data?.property || property;
	const [image, setImage] = useState(_record?.[_property] && _record.downloadFile(_property) + `?v=${_record.updatedAt}`);
	const context = useContext(FormContext);
	const _form = __data?.form || context?.form;

	const onDrop = async (acceptedFiles) => {
		const file = acceptedFiles[0];
		const reader = new FileReader();
		reader.onload = () => {
			const imageDataUrl = reader.result;
			setImage(imageDataUrl);
		};

		reader.readAsDataURL(file);

		if (__data) {
			if (_record.id) {
				await _record.patchAttributes({ [_property]: file.name });
				await _record.uploadFile(_property, file);
			} else {
				if (onChange) onChange(file.name);
				_form?.setFile({
					file: file,
					property: _property,
				});
			}
		} else {
			_record[_property] = file.name;
			if (onChange) onChange(file, _record);
		}
	};

	const deleteImage = async () => {
		if (_record.id) {
			await _record.patchAttributes({ [_property]: null });
			await _record.deleteFile(_property);
		} else {
			if (onChange) onChange();
			_record[_property] = null;
			_form?.removeFile(_property);
		}
		setImage(null);
	};

	const { getRootProps, getInputProps } = useDropzone({ onDrop });

	return (
		<div {...getRootProps()} className='image-uploader'>
			<p className='placeholder-uploader'>{t('file.moveOrSelectFile')}</p>
			<input {...getInputProps()} accept='image/*' />
			{!image && <img src={noImage} alt='Placeholder' loading='lazy' />}
			{image && (
				<>
					<DeleteIconButton
						onConfirm={deleteImage}
						popPosition='top'
						className='delete-btn-uploader'
						portalClassName='popup-confirm-uploader'
						noBorder
					/>
					<img src={image} alt='Uploaded' loading='lazy' />
				</>
			)}
		</div>
	);
};
