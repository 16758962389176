import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import t from 'i18n';
import { Field, NumberInput, Popover, Row } from '@smartplatform/ui';
import { Form } from 'components';
import { CATALOG_PATH } from 'client/modules/sales/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

@observer
export class CategoryCreate extends Component {
	@observable record = null;
	@observable products = [];

	selectedCategoryId;
	categoryId;
	filter;

	constructor(props) {
		super(props);
		this.categoryId = props.match.params.categoryId ? parseInt(props.match.params.categoryId) : null;
		this.init();
	}

	init = async () => {
		const { search } = store.route._location();
		const { categoryParentId } = store.route._decodeFunction(search);
		this.filter = { where: { and: [] } };
		if (!this.categoryId) {
			this.selectedCategoryId = categoryParentId ? parseInt(categoryParentId) : null;
			this.record = new store.model.ProductCategory();
			this.record.parentId = this.selectedCategoryId;
		} else {
			this.record = await store.model.ProductCategory.findById(this.categoryId, { include: ['products'] });
			this.products = this.record.products();
			this.filter.where.and.push({ id: { neq: this.categoryId } });
		}
	};

	goBack = () => {
		const params = {};
		if (this.selectedCategoryId) {
			params.categoryParentId = this.selectedCategoryId;
		}

		store.route.push({
			path: CATALOG_PATH,
			params,
		});
	};

	renderLabelPriority = () => (
		<>
			{t('category.priority')}
			<Popover content={t('category.priorityHint')} position='top'>
				<FontAwesomeIcon icon={faQuestionCircle} />
			</Popover>
		</>
	);

	render() {
		if (!this.record) return null;
		return (
			<Form
				record={this.record}
				onSave={this.goBack}
				onCancel={this.goBack}
				cancelTitle={t('catalog.goBack')}
				onDelete={this.goBack}
				disableSave={!this.record?.name}
				disableDelete={this.products.length}
				stay
			>
				<Row>
					<Field property='name' label={t('category.name')} isRequired />
					<Field property='name' relation='parent' label={t('category.parent')} filter={this.filter} />
				</Row>
				<Row>
					<Field property='priority' label={this.renderLabelPriority()}>
						<NumberInput />
					</Field>
				</Row>
			</Form>
		);
	}
}
