import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import Menu from './menu/Menu';
import Footer from './Footer';
import store from 'client/store';
import './style.scss';

@observer
export default class Sidebar extends React.Component {
	
	onOverlayClick = () => {
		store.local.menuState = false;
		store.ui.menu = false;
	};
	
	render() {
		return <>
			<div className={'mobile-overlay' + (store.ui.menu ? ' active' : '')} onClick={this.onOverlayClick} />
			<div className={'sidebar' + (store.ui.menu ? ' active' : '')}>
				<Menu />
				<Footer />
			</div>
		</>;
	}
	
}
