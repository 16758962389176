import React from 'react';
import './style.scss';
import { Avatar } from '@smartplatform/ui';
import store from 'client/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut, faUser, faVideo } from '@fortawesome/free-solid-svg-icons';
import t from 'i18n';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Billing } from '@app/layout/header/Billing';

@observer
export default class MobileAccountMenu extends React.Component {
	@observable showMenu = false;
	@observable billingInstance = null;
	constructor(props) {
		super(props);
	}

	toggleMenu = () => {
		if (store.ui.menu) return null;
		this.showMenu = !this.showMenu;
		if (this.billingInstance) this.billingInstance.close();
	};

	logOut = () => {
		store.model.logout();
		this.showMenu = false;
	};

	goProfile = () => {
		store.route.push({ path: '/profile' });
		this.showMenu = false;
	};

	getInstance = (instance) => {
		this.billingInstance = instance;
	};

	render() {
		const videoUrl = store.model.config?.videoLessonsURL || 'https://www.youtube.com/@smart-erp_pro/videos';

		return (
			<div className='mobile-account-menu'>
				<div onClick={this.toggleMenu}>
					<Avatar user={store.model.user} size={26} />
				</div>
				<div className={classNames('items-account-menu', { active: this.showMenu })}>
					<div className='item-account-menu' onClick={this.goProfile}>
						<FontAwesomeIcon icon={faUser} />
						{t('user.profile')}
					</div>
					<Billing getInstance={this.getInstance} />
					<a className='item-account-menu' href={videoUrl} target='_blank' onClick={this.toggleMenu}>
						<FontAwesomeIcon icon={faVideo} />
						{t('video')}
					</a>
					<div className='item-account-menu' onClick={this.logOut}>
						<FontAwesomeIcon icon={faSignOut} />
						{t('logout')}
					</div>
				</div>
				{this.showMenu && <div className='black-bachground' onClick={this.toggleMenu} />}
			</div>
		);
	}
}

