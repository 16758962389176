import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Button, ConfirmPopup } from 'components';
import classNames from 'classnames';
import t from 'i18n';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

@observer
export class DeleteButton extends React.Component {
	@observable showPopup = false;
	openPopup = () => (this.showPopup = true);
	closePopup = () => (this.showPopup = false);
	render() {
		const {
			confirmMessage = t('delete'),
			text = t('delete'),
			onConfirm,
			portalClassName,
			noBorder,
			popPosition,
			disabled,
			className,
			...restButtonProps
		} = this.props;

		return (
			<>
				<Button
					variant='danger'
					startIcon={<FontAwesomeIcon icon={faTrashCan} />}
					className={classNames('delete-btn', className, { 'no-border': noBorder })}
					disabled={disabled}
					text={text}
					onClick={this.openPopup}
					{...restButtonProps}
				/>
				{this.showPopup && (
					<ConfirmPopup
						onClose={this.closePopup}
						title={t('delete')}
						description={t('deleteDescription')}
						buttonText={t('delete')}
						buttonVariant='danger'
						onConfirm={onConfirm}
					/>
				)}
			</>
		);
	}
}

DeleteButton.propTypes = {
	onConfirm: PropTypes.func.isRequired,
	confirmMessage: PropTypes.any,
	portalClassName: PropTypes.string,
	noBorder: PropTypes.bool,
	disabled: PropTypes.bool,
	popPosition: PropTypes.oneOf([
		'topLeft',
		'top',
		'topRight',
		'leftTop',
		'left',
		'leftBottom',
		'rightTop',
		'right',
		'rightBottom',
		'bottomLeft',
		'bottom',
		'bottomRight',
	]),
};

