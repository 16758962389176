import { renderUser } from 'client/tools';

/**
 * Карта рендерн-функций для моделей в реляциях дополнительных полей 
 */

export const MODEL_RENDERS = {
	Task: (r) => r?.name,
	Order: (r) => r?.description,
	Document: (r) => r?.name,
	Project: (r) => r?.name,
	User: (r) => (r ? renderUser(r) : undefined),
};

