import { set } from 'lodash';

export const parseConfig = (configRecords) => {
	const parsedConfig = {};
	for (let config of configRecords) {
		set(parsedConfig, config.code, config.hiddenValue || config.value);
	}
	return parsedConfig;
};

