import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import { DialogLogItem } from 'client/modules/messages/dialogs/single/history/DialogLogItem';
import { ListScrollLoad } from 'components';
import { Loader } from '@smartplatform/ui';
import HistoryDeltaPopup from 'components/history-delta-popup/HistoryDeltaPopup';

@inject('store')
@observer
export class DialogLogs extends React.Component {
	page = 1;
	itemsPerPage = 15;
	@observable records = [];
	@observable historyPopup = null;
	@observable isLoading = true;
	constructor(props) {
		super(props);
		this.store = props.store;
		this.fetchRecords();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.historyOrder !== this.props.historyOrder) {
			this.reload();
		}
	}

	reload = () => {
		this.page = 1;
		this.fetchRecords();
	};

	fetchRecords = async () => {
		this.isLoading = true;
		const skip = (this.page - 1) * this.itemsPerPage;
		const records = await store.model.DialogLog.find({
			skip,
			where: { and: [{ dialogId: this.store.id }, { updated: { neq: null } }] },
			order: this.props.historyOrder,
			limit: this.itemsPerPage,
			include: ['owner'],
		});

		this.records = this.page > 1 ? [...this.records, ...records] : records;
		this.isLoading = false;
	};

	showHistoryPopup = async (field, log, index) => {
		const [oldLog] = await store.model.DialogLog.find({
			where: {
				and: [{ id: { lt: log.id } }, { id: { neq: log.id } }],
				dialogId: log.dialogId,
				[field]: { neq: null },
			},
			limit: 1,
			order: 'createdAt desc',
		});

		this.historyPopup = {
			log: { record: log },
			oldLog: oldLog,
			onClose: () => (this.historyPopup = null),
			field: field,
			baseModelRelationName: 'dialog',
		};
	};

	renderDialogLog = (record, index) => {
		return <DialogLogItem key={record.id} log={record} index={index} onShowPopup={this.showHistoryPopup} />;
	};

	onLoadMore = async () => {
		this.page = this.page + 1;
		await this.fetchRecords();
	};

	render() {
		return (
			<>
				{(!this.isLoading || !!this.records.length) && (
					<ListScrollLoad records={this.records} renderItem={this.renderDialogLog} onLoadMore={this.onLoadMore} />
				)}
				{this.isLoading && !this.records.length && <Loader size={20} />}
				{this.historyPopup && <HistoryDeltaPopup {...this.historyPopup} />}
			</>
		);
	}
}
