import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';

import VariantPrice from './VariantPrice';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@inject('productStore')
@observer
export default class VariantPrices extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { productStore, nomenclature, onChange } = this.props;
		const { amountRanges } = productStore;

		const ranges = amountRanges.filter((range) => !!range.id);

		return (
			<div className='variant-prices'>
				{ranges.length > 0 && (
					<div className='ranges'>
						<label>{t('quantity')}</label>
						<label>{t('price')}</label>
						{ranges.map((range) => {
							return <VariantPrice key={range.id} range={range} nomenclature={nomenclature} onChange={onChange} />;
						})}
					</div>
				)}
			</div>
		);
	}
}

