import store from 'client/store';
import { numberToLocaleString } from 'client/tools/locale';
export const CurrencyValue = (props) => {
	const _value = props.value || 0;
	const currency = props.currency;
	return numberToLocaleString(_value, {
		style: currency ? 'currency' : undefined,
		currency: currency || undefined,
	});
};

