import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faBars, faComments } from '@fortawesome/free-solid-svg-icons';
import { fio, fioShort } from 'client/tools';
import { Desktop, NotPhone, Phone } from 'components';
import logoMini from 'client/img/logo-mini.svg?url';
import logoLight from 'client/img/logo-light.svg?url';
import logoDark from 'client/img/logo-dark.svg?url';
import { AutoAnimate, Avatar, Button } from '@smartplatform/ui';
import { Billing } from './Billing';
import store from 'client/store';
import './style.scss';
import t from 'i18n';
import MobileAccountMenu from '@app/layout/header/mobile/MobileAccountMenu';
import { observable } from 'mobx';
import { GlobarSearch } from './GlobalSearch';
import Language from './Language';

const AVATARS_COUNT = 20;
@observer
export default class Header extends React.Component {
	@observable showAccountMenu = false;
	refUserSection = null;

	constructor(props) {
		super(props);
		store.ui.menu = store.local.menuState;
	}

	toggleMenu = () => {
		if (store.local.menuState) {
			store.local.menuState = false;
			store.ui.menu = store.local.menuState;
		} else {
			store.local.menuState = true;
			store.ui.menu = store.local.menuState;
		}

		store.local.save();
	};

	goToBack = () => (typeof store.ui.backRoute === 'function' ? store.ui.backRoute() : store.route.push(store.ui.backRoute));

	toggleFeedbackPopup = () => {
		this.closeAccountMenu();
		store.ui.showFeedbackPopup = !store.ui.showFeedbackPopup;
	};

	toggleAccountMenu = () => {
		this.showAccountMenu = !this.showAccountMenu;
	};

	closeAccountMenu = () => {
		this.showAccountMenu = false;
	};

	logOut = () => {
		this.closeAccountMenu();
		store.model.logout();
	};

	handleClickOutUserSection = (event) => {
		if (this.refUserSection && !this.refUserSection.contains(event.target)) {
			this.closeAccountMenu();
		}
	};

	componentDidMount() {
		window.addEventListener('mousedown', this.handleClickOutUserSection);
	}

	componentWillUnmount() {
		window.removeEventListener('mousedown', this.handleClickOutUserSection);
	}

	onMountUserSection = (el) => {
		this.refUserSection = el;
	};

	render() {
		const onlineUsers = [...store.onlineUsersMap.values()].reverse();
		const videoUrl = store.model.config?.videoLessonsURL || 'https://www.youtube.com/@smart-erp_pro/videos';

		return (
			<header className='topbar'>
				<div className={store.ui.menu ? 'header' : 'header header-minimized'}>
					<div className='mobile-menu' onClick={this.toggleMenu}>
						<FontAwesomeIcon icon={faBars} />
					</div>
					<div className={`title ${store.ui.menu ? 'visible' : ''}`}>
						<div className='logo'>
							<Link to='/'>
								<Phone>
									<img src={'/api' + logoMini} />
								</Phone>
								<NotPhone>
									{store.local.isDarkMode ? (
										<img src={'/api' + logoDark} />
									) : (
										<img src={'/api' + logoLight} />
									)}
								</NotPhone>
							</Link>
						</div>
					</div>
				</div>
				<div className='toolbar'>
					<Desktop>
						<div className='toolbar-left'>
							{store.ui.backRoute && (
								<Button onClick={this.goToBack} className='back-btn'>
									<FontAwesomeIcon icon={faAngleLeft} />
								</Button>
							)}
							<span>{store.ui.title || ''}</span>
						</div>
					</Desktop>
					{store.model.isAuthorized && (
						<>
							<NotPhone>
								<div className='toolbar-right'>
									<Desktop>
										<GlobarSearch />
									</Desktop>
									<div />
									<div className='end'>
										{onlineUsers.length > 0 && (
											<AutoAnimate className='online-users'>
												<div className='online'>{t('online')}</div>
												<div className='users'>
													{onlineUsers.map(
														(user, index) =>
															index < AVATARS_COUNT && (
																<div title={fio(user)} key={user.id}>
																	<Avatar user={user} size={26} />
																</div>
															)
													)}
												</div>
												{onlineUsers.length > AVATARS_COUNT && <div>+{onlineUsers.length - AVATARS_COUNT}</div>}
											</AutoAnimate>
										)}
										<Billing />
										<div className='user-section' ref={this.onMountUserSection}>
											<div className='profile-link' onClick={this.toggleAccountMenu}>
												<Avatar user={store.model.user} size={26} />
												<NotPhone>
													<span className='fio'>{fioShort(store.model.user)}</span>
												</NotPhone>
											</div>
											{this.showAccountMenu && (
												<div className='wrapper-account-menu'>
													<Link to='/profile' onClick={this.closeAccountMenu}>
														{t('user.profile')}
													</Link>
													<a href={videoUrl} target='_blank' onClick={this.closeAccountMenu}>
														{t('video')}
													</a>
													<p onClick={this.toggleFeedbackPopup}>{t('support')}</p>
													<p className='logout' onClick={this.logOut}>
														{t('logout')}
													</p>
												</div>
											)}
										</div>
									</div>
								</div>
							</NotPhone>
							<Phone>
								<MobileAccountMenu />
							</Phone>
							<Language />
						</>
					)}
				</div>
			</header>
		);
	}
}

