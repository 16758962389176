import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { ErrorMessage } from 'components/auth/components';
import { Loader } from '@smartplatform/ui';
import t from 'i18n';
import * as VKID from '@vkid/sdk';
import store from 'client/store';
import { getAccessTokenFromCookie } from 'client/tools';
import { generateSHA256Hash } from 'components/auth/social-network/tools/generateSHA256Hash';

@observer
export class SocialAuth extends React.Component {
	@observable error;
	constructor(props) {
		super(props);
		this.urlSearchParams = new URLSearchParams(window.location.search);
		this.provider = props.match.params?.provider || null;
		this.init();
	}

	init = async () => {
		try {
			if (this.provider === 'vk') {
				await this.vkAuth();
			} else if (this.provider === 'yandex') {
				await this.socAuth(store.mergedConfig.authentication.socialNetwork.yandexClientSecret, 'loginYandex');
			} else if (this.provider === 'google') {
				await this.socAuth(store.mergedConfig.authentication.socialNetwork.googleClientSecret, 'loginGoogle');
			} else if (this.provider === 'mailru') {
				await this.socAuth(store.mergedConfig.authentication.socialNetwork.mailClientSecret, 'loginMail');
			} else {
				this.error = t('ERROR_SOC_NET');
				return null;
			}

			if (getAccessTokenFromCookie()) {
				await store.init();
				store.route.push({ path: '/', search: {}, params: {} });
			}
		} catch (error) {
			const message = error?.code || error.message;
			this.error = message === 'USER_BLOCKED' ? t('USER_BLOCKED') : t('ERROR_SOC_NET');
			console.error(error);
		}
	};

	vkAuth = async () => {
		const state = this.urlSearchParams.get('state');
		const hash = await generateSHA256Hash(`${state}:${store.mergedConfig.authentication.socialNetwork.vkClientSecret}`);

		if (!store.local.socialAuth.state || hash !== store.local.socialAuth.state) throw 'invalid state';

		VKID.Config.init({
			app: store.mergedConfig.authentication.socialNetwork.vkApplicationId,
			redirectUrl: `${location.origin}/auth/vk`,
			state: state,
			scope: 'email phone',
		});
		const vkDate = await VKID.Auth.exchangeCode(this.urlSearchParams.get('code'), this.urlSearchParams.get('device_id'));
		await store.model.User.loginVK(vkDate);
	};

	socAuth = async (clientSecret, remoteMethodName) => {
		if (this.urlSearchParams.get('error')) throw this.urlSearchParams.get('error');

		const state = this.urlSearchParams.get('state');
		const hash = await generateSHA256Hash(`${state}:${clientSecret}`);

		if (!store.local.socialAuth.state || hash !== store.local.socialAuth.state) throw 'invalid state';

		const params = {
			code: this.urlSearchParams.get('code'),
		};

		await store.model.User[remoteMethodName](params);
	};

	render() {
		if (this.error) return <ErrorMessage field={this.error} />;
		return <Loader size={48} />;
	}
}
