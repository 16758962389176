import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Delimiter from 'client/img/next-switcher.svg';

import './style.scss';

export default class Breadcrumbs extends React.Component {
	static propTypes = {
		crumbs: PropTypes.array,
	};

	render() {
		const { crumbs } = this.props;

		const delimiter = <Delimiter className='delimiter' />;

		return (
			<div className='breadcrumbs'>
				{crumbs
					.map((item, index) => {
						if (index === crumbs.length - 1) {
							return <span className='last-item'>{item.title}</span>;
						} else {
							return (
								<Link to={item.path} className='link'>
									{item.title}
								</Link>
							);
						}
					})
					.reduce((prev, curr) => [prev, delimiter, curr])}
			</div>
		);
	}
}
