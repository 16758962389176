import { observable } from 'mobx';
import store from 'client/store';

export default class AmountRangeStore {
	@observable rangesToSave = new Set();
	@observable rangesToDelete = new Set();
	@observable notValidRanges = new Map();
	@observable amountRanges = [];

	originalValues;

	constructor(productStore) {
		this.productId = productStore.productId;
		this.amountRanges = [...productStore.amountRanges];
		this.productStore = productStore;
		this.setOrigininalValues();
		this.checkValidation();
	}

	get disableSave() {
		return this.notValidRanges.size > 0;
	}

	get showEditButtons() {
		return Boolean(this.rangesToDelete.size || this.rangesToSave.size);
	}

	checkToSave = () => {
		this.amountRanges.forEach((range) => {
			const originalValues = this.originalValues.get(range);
			if (!originalValues) {
				this.rangesToSave.add(range);
			} else if (originalValues.amountFrom !== range.amountFrom || originalValues.amountTo !== range.amountTo) {
				this.rangesToSave.add(range);
			} else this.rangesToSave.delete(range);
		});
	};

	checkValidation = () => {
		const notValidRanges = new Map();
		this.amountRanges.forEach((range, index) => {
			const notValidFields = {};
			// у первой строчки должен быть заполнен amountFrom

			if (!range.amountFrom || range.amountFrom < 1) {
				notValidFields.amountFrom = true;
			}

			// у всех кроме последней должен быть заполнен amountTo
			const isLastRange = this.amountRanges.length === index + 1;
			if (!isLastRange && !range.amountTo) {
				notValidFields.amountTo = true;
			}

			// проверка валидности текущей строки
			if (range.amountTo && range.amountFrom && range.amountFrom >= range.amountTo) {
				notValidFields.amountTo = true;
				notValidFields.amountFrom = true;
			}

			// сравнение со следущей строкой
			if (range.amountTo >= this.amountRanges[index + 1]?.amountFrom) {
				notValidFields.amountTo = true;
			}

			// сравнение с предыдущей строкой
			if (range.amountFrom <= this.amountRanges[index - 1]?.amountTo) {
				notValidFields.amountFrom = true;
			}

			if (Object.keys(notValidFields).length) notValidRanges.set(range, notValidFields);
		});
		this.notValidRanges = notValidRanges;
	};

	setOrigininalValues = () => {
		this.originalValues = this.amountRanges.reduce((map, amountRange) => {
			map.set(amountRange, { amountFrom: amountRange.amountFrom, amountTo: amountRange.amountTo });
			return map;
		}, new Map());
	};

	createRange = async () => {
		const newRange = new store.model.ProductAmountRange({});
		newRange.productId = this.productId;
		newRange.amountFrom = this.amountRanges.length ? this.amountRanges[this.amountRanges.length - 1]?.amountTo + 1 : 1;
		this.amountRanges.push(newRange);
		this.checkValidation();
		this.checkToSave();
	};

	onCancel = () => {
		for (const [record, originalValues] of this.originalValues) {
			Object.assign(record, originalValues);
		}
		this.amountRanges.replace([...this.originalValues.keys()]);
		this.rangesToSave.clear();
		this.rangesToDelete.clear();
		this.notValidRanges.clear();
	};

	onSave = async () => {
		const promises = [];
		this.rangesToSave.forEach((rec) => promises.push(rec.save()));
		this.rangesToDelete.forEach((rec) => promises.push(rec.delete()));
		await Promise.all(promises);
		this.productStore.amountRanges.replace(this.amountRanges);
		this.rangesToSave.clear();
		this.rangesToDelete.clear();
		this.notValidRanges.clear();
		this.setOrigininalValues();
	};
}

