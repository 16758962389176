import classNames from 'classnames';
import React from 'react';
import './labelField.scss';

export const LabelField = ({ children, label, className, text, isRequired, labelClassName }) => {
	const _className = classNames('form-field', 'label-field', 'is-required', className);
	const _labelClassName = classNames({ 'is-required': isRequired }, labelClassName);
	if (text) text = <span className='text'>{text}</span>;
	return (
		<div className={_className}>
			<label className={_labelClassName}>{label}</label>
			{text || children}
		</div>
	);
};

